import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DocumentsRequests {
    constructor(private http: HttpClient) {}
    async upload(objectName: string, objectId: string, files: any[], label?: string) {
        const formData = new FormData();
        for (const file of files) {
            const date = `${new Date().toLocaleDateString('de')}_${new Date().toLocaleTimeString('de')}`;
            const name =
                file.editedName && file.editedName.length
                    ? file.editedName
                    : `${file.name.split('.')[0]}_${date}.${file.name.split('.')[1]}`;
            formData.append(name, file.file, name);
        }

        const queryParam = label ? `?label=${encodeURIComponent(label)}` : '';

        return this.http
            .post(`${environment.apiBase}${objectName}/${objectId}/documents${queryParam}`, formData)
            .toPromise();
    }

    async rename(objectName: string, objectId: string, docType: 'imgs' | 'pdfs', index: number, docName: string) {
        return this.http
            .post(`${environment.apiBase}${objectName}/${objectId}/documents/${docType}/${index}/rename`, {
                name: docName,
            })
            .toPromise();
    }

    async delete(objectName: string, objectId: string, docType: 'imgs' | 'pdfs', index: number) {
        return this.http
            .delete(`${environment.apiBase}${objectName}/${objectId}/documents/${docType}/${index}`)
            .toPromise();
    }
}
