<ion-app [ngStyle]="{ width: appWidth + 'px' }">
    <ion-router-outlet
        *ngIf="
            !((auth.maintenance$ | async) || (auth.nsMaintenance$ | async));
            else maintenanceScreen
        "></ion-router-outlet>
    <ng-template #maintenanceScreen>
        <app-maintenance class="h-100"></app-maintenance>
    </ng-template>
</ion-app>
