import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { CraftsmanSelectionComponent } from './craftsman-selection.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ElasticSearchComponentModule } from '../elastic-search/elastic-search.component.module';

@NgModule({
    declarations: [CraftsmanSelectionComponent],
    exports: [CraftsmanSelectionComponent],
    imports: [
        ComponentsSharedModule,
        TopbarComponentModule,
        ReactiveFormsModule,
        ElasticSearchComponentModule,
        FormsModule,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CraftsmanSelectionComponentModule {}
