<ion-row *ngIf="userService.user" class="main w-100 h-100 ion-align-items-center ion-justify-content-center">
    <img *ngIf="secondarySplashLogo" [src]="secondarySplashLogo" />
</ion-row>
<ion-row
    *ngIf="!userService.user || (isNativePlatform && !hasNetwork)"
    class="w-100 h-100 ion-align-items-center ion-justify-content-center default-bg">
</ion-row>
<ion-row *ngIf="userService.user" class="w-100 ion-padding spinner-row ion-justify-content-center">
    <ion-spinner></ion-spinner>
</ion-row>
