import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import { PopupService } from '../../services/popup/popup.service';
import { LanguageService } from '../../services/language/language.service';
import { UserService } from '../../services/user/user.service';
import { DataService } from '../../services/data/data.service';
import { ChangePasswordComponent } from '../../modals/change-password/change-password.component';
import { environment } from 'src/environments/environment';
import { OriginService } from 'src/app/services/origin/origin.service';
import { Capacitor } from '@capacitor/core';
import { App, AppInfo } from '@capacitor/app';
import { CustomEmailValidator } from '../../validators/custom-email-validator';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
    protected readonly environment = environment;
    form: FormGroup;
    showForm: boolean = false;
    currentLanguageName: string;
    showDownloadButtons: boolean = false;
    logoUrl: string = '';
    public appInfo: AppInfo;
    badges;
    titleKey: string;

    constructor(
        private loadingController: LoadingController,
        private authProvider: AuthService,
        private fb: FormBuilder,
        private popupService: PopupService,
        private translate: TranslateService,
        private languageService: LanguageService,
        private modalCtrl: ModalController,
        private userService: UserService,
        private navController: NavController,
        private dataService: DataService,
        public alertController: AlertController,
        public originService: OriginService
    ) {}

    async ngOnInit() {
        if (Capacitor.isNativePlatform()) {
            this.appInfo = await App.getInfo();
        }

        this.logoUrl = `${environment.apiBase}namespaces/image/logo.png?X-App-Origin=${this.originService.origin}`;
        const langSubscription = this.translate.onLangChange.subscribe(async (languageChange) => {
            this.badges = await this.userService.getBadges();
            langSubscription.unsubscribe();
        });
        if (this.originService.whiteLabelKey) {
            this.titleKey = `auth.login.title.manager.${this.originService.whiteLabelKey}`;
        } else {
            this.titleKey = 'auth.login.title.manager';
        }
        this.form = this.fb.group({
            email: ['', [Validators.required, CustomEmailValidator.emailFormat]],
            password: ['', [Validators.required]],
        });

        this.currentLanguageName = this.languageService.getFullLanguageName(this.translate.currentLang);
        if (!Capacitor.isNativePlatform()) {
            this.showDownloadButtons = true;
        }
        this.showForm = true;
    }

    async onLogin() {
        // init and start loader
        const loading = await this.loadingController.create();
        await loading.present();

        try {
            // try to login and then setRoot to TabsPage
            const tokens = await this.authProvider.login(this.email.value, this.password.value);

            if (this.authProvider.hasValidMultiNamespaceTokens(tokens)) {
                await loading.dismiss();
                await this.navController.navigateRoot('namespace-chooser', {
                    state: {
                        email: this.email.value,
                    },
                });
            }

            await this.dataService.initializeEssentialAppData();

            await loading.dismiss();

            await this.dataService.setupAfterLogin();
        } catch (error) {
            await loading.dismiss();
            // if we got an error, present error alert
            console.error(error);
            await this.popupService.showAlert(
                this.translate.instant('login.error'),
                this.translate.instant('login.error-text'),
                [this.translate.instant('login.ok')]
            );
        }
    }

    get email() {
        return this.form.get('email');
    }

    private get password() {
        return this.form.get('password');
    }

    async sendPasswordResetMail() {
        if (this.email.value) {
            this.authProvider
                .sendPasswordResetMail(this.email.value)
                .then(async () => {
                    const alert = await this.alertController.create({
                        header: this.translate.instant('login.reset-mail.successful'),
                        message: this.translate.instant('login.reset-mail-sent'),
                        buttons: [this.translate.instant('login.ok')],
                    });
                    await alert.present();
                })
                .catch(async (err) => {
                    console.error('Change Password failed', err);
                    let text = 'login.reset-mail-error';
                    switch (err.status) {
                        case 404:
                            text = 'login.email-not-exists';
                            break;
                    }
                    const alert = await this.alertController.create({
                        header: this.translate.instant('login.reset-mail.error'),
                        message: this.translate.instant(text),
                        buttons: [this.translate.instant('login.ok')],
                    });
                    await alert.present();
                });
        } else {
            const modal = await this.modalCtrl.create({
                component: ChangePasswordComponent,
                componentProps: { header: 'login.forgot-button' },
            });
            await modal.present();
        }
    }
}
