export enum SearchEntity {
    PROPERTY = 'PROPERTY',
    FLAT = 'FLAT',
    USER = 'PERSONAL',
    USER_CREATE = 'PERSONAL_CREATE',
    CRAFTSMAN_FILTERED = 'CRAFTSMAN_FILTERED',
    CRAFTSMAN = 'CRAFTSMAN',
    TICKET = 'TICKET',
    BROADCAST = 'BROADCAST',
    GROUP = 'GROUP',
    SERVICE_PROVIDER = 'SERVICE_PROVIDER',
    ORGANISATION = 'ORGANISATION',
}
