import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';
import { DocumentsRequests } from '../../util/documents-requests';
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { BehaviorSubject, Observable, combineLatest, Subscription, of, firstValueFrom } from 'rxjs';
import { NewTicket, Ticket } from '../../models/ticket';
import { map } from 'rxjs/operators';
import { TextObjAggregated } from '../../models/text-obj-aggregated';
import { TextService } from '../text/text.service';
import { BaseDataService } from '../../util/base-data-service';
import { TicketNode } from '../../models/ticket-node';
import { TicketTemplate } from '../../models/ticket-template';
import { OfferStatus, TicketStatus, TicketTypes } from '../../models/ticket-status.enum';
import {
    collection,
    collectionData,
    doc,
    docSnapshots,
    Firestore,
    getDocs,
    limit,
    orderBy,
    query,
    where,
    limit as firestoreLimit,
} from '@angular/fire/firestore';
import { ElasticService } from '../elastic/elastic.service';
import { PropertyService } from '../property/property.service';
import { ObjectDisplayFormatPipe } from 'src/app/pipes/object-display-format/object-display-format';
import { convertFirestoreDate } from '../../util/util';
import { Timeout } from '../../util/timeout';

@Injectable({
    providedIn: 'root',
})
export class TicketsService implements BaseDataService {
    private sub: Subscription;

    tickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    initialTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    acceptedTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    ticketsWithOffers$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    inWorkTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    finishedTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    declinedTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    canceledTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    private LOAD_STEP = 10;
    private texts;
    private states = {
        INIT: 0,
        ACCEPTED: 0,
        IN_WORK: 0,
        OFFERS: 0,
        FINISHED: 0,
        CANCELED: 0,
        DECLINED: 0,
    };
    ticketsCount$: BehaviorSubject<any> = new BehaviorSubject<any>(this.states);

    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private textService: TextService,
        private http: HttpClient,
        private apiService: ApiService,
        private documentsRequests: DocumentsRequests,
        private documentUtilService: DocumentUtilService,
        private propertyService: PropertyService,
        private elasticService: ElasticService,
        private displayFormat: ObjectDisplayFormatPipe
    ) {}

    async initialize(withFinished: boolean = false) {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (withFinished) {
            this.sub = await this.initializeTicketObservables([
                this.getInitialTickets(),
                this.getTicketsAccepted(),
                this.getTicketsOffer(),
                this.getTicketsInWork(),
                this.getTicketsFinished(),
                this.getTicketsDeclined(),
                this.getTicketsCanceled(),
            ]);
        } else {
            this.sub = await this.initializeTicketObservables([
                this.getInitialTickets(),
                this.getTicketsAccepted(),
                this.getTicketsOffer(),
                this.getTicketsInWork(),
                of(undefined),
                of(undefined),
                of(undefined),
            ]);
        }
    }

    private async initializeTicketObservables(ticketObservableArray: Observable<Ticket[]>[]) {
        this.texts = await this.textService.getTexts();
        return combineLatest(ticketObservableArray)
            .pipe(
                map(([init, accepted, offer, inWork, finished, declined, canceled]) => {
                    this.addTexts(init, this.texts);
                    this.addTexts(accepted, this.texts);
                    this.addTexts(offer, this.texts);
                    this.addTexts(inWork, this.texts);
                    this.addTexts(finished, this.texts);
                    this.addTexts(declined, this.texts);
                    this.addTexts(canceled, this.texts);
                    return {
                        init,
                        accepted,
                        offer,
                        inWork,
                        finished,
                        declined,
                        canceled,
                    };
                })
            )
            .subscribe(async (tickets) => {
                this.userService.userLanguage$.asObservable().subscribe(async (lang) => {
                    if (lang) {
                        await this.setupTickets(tickets);
                    }
                });
            });
    }

    private async setupTickets(tickets, loadMore = false) {
        const allTickets = [];
        const managerPromises = [];
        const managerCache = [];
        const propertyPromises = [];
        const propertyCache = [];
        for (const key of Object.keys(tickets)) {
            if (tickets[key]?.length) {
                for (const ticket of tickets[key]) {
                    if (!propertyCache.includes(ticket.propertyId)) {
                        propertyCache.push(ticket.propertyId);
                        propertyPromises.push(this.propertyService.getPropertyById(ticket.propertyId));
                    }

                    if (!managerCache.includes(ticket.propertyManagerIds[0])) {
                        managerCache.push(ticket.propertyManagerIds[0]);
                        managerPromises.push(this.userService.getManager(ticket.propertyManagerIds[0]));
                    }
                }
            }
        }

        const managerResult = await Promise.all(managerPromises);
        const propertyResult = await Promise.all(propertyPromises);

        for (const key of Object.keys(tickets)) {
            if (tickets[key]?.length) {
                for (const ticket of tickets[key]) {
                    ticket.manager = managerResult.find((result) => result.id === ticket.propertyManagerIds[0]);
                    const property = propertyResult.find((result) => result.id === ticket.propertyId);

                    if (property) {
                        ticket.property = this.displayFormat.formatProperty(property);
                    }

                    ticket.deputy = !ticket.manager.vacation && ticket.manager.uid !== this.userService.user.uid;
                }
                allTickets.push(...tickets[key]);
            }
        }

        const counts = this.states;
        await Promise.all(
            Object.keys(counts).map(async (key) => {
                counts[key] = await this.elasticService.getTicketsCount({
                    'status.keyword': key,
                    archive: false,
                    'type.keyword': 'REQUEST',
                    propertyManagerIds: this.userService.user.uid,
                });
            })
        );
        this.ticketsCount$.next(counts);
        this.mergeTickets(this.tickets$, allTickets, loadMore);
        this.mergeTickets(this.initialTickets$, tickets.init, loadMore);
        this.mergeTickets(this.acceptedTickets$, tickets.accepted, loadMore);
        this.mergeTickets(this.inWorkTickets$, tickets.inWork, loadMore);
        this.mergeTickets(this.ticketsWithOffers$, tickets.offer, loadMore);
        this.mergeTickets(this.finishedTickets$, tickets.finished, loadMore);
        this.mergeTickets(this.declinedTickets$, tickets.declined, loadMore);
        this.mergeTickets(this.canceledTickets$, tickets.canceled, loadMore);
    }

    private mergeTickets(oldTickets: BehaviorSubject<any>, newTickets: any[], loadMore = false) {
        if (loadMore) {
            const ticketsToDeploy = [];
            for (const newTicket of newTickets) {
                if (!(oldTickets.getValue() || []).some((oldTicket) => oldTicket.id === newTicket.id)) {
                    ticketsToDeploy.push(newTicket);
                }
            }
            oldTickets.next((oldTickets.getValue() || []).concat(ticketsToDeploy));
        } else {
            oldTickets.next(newTickets);
        }
    }

    private getObservableQueryOnTickets(status: string, ticketNoOffset: number = 99999999): Observable<Ticket[]> {
        const ticketsCollection = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketsHash`);
        const ticketsQuery = query(
            ticketsCollection,
            where('type', '==', TicketTypes.REQUEST),
            where('propertyManagerIds', 'array-contains', this.userService.user.id),
            where('status', '==', status),
            where('ticketNo', '<', ticketNoOffset),
            where('archive', '==', false),
            orderBy('ticketNo', 'desc'),
            limit(this.LOAD_STEP)
        );

        return collectionData(ticketsQuery, { idField: 'id' }).pipe(
            map((tickets: any[]) => {
                return tickets.map((ticket) => this.transformTicketData(ticket));
            })
        );
    }

    private transformTicketData(data: any): Ticket {
        if (data.createdOn) {
            data.createdOn = data.createdOn.toDate();
        }
        if (data.offers) {
            Object.keys(data.offers).forEach((key) => {
                const offer = data.offers[key];
                if (offer.statusChanges) {
                    offer.statusChanges.forEach((status: any) => {
                        status.timestamp = status.timestamp.toDate();
                    });
                    offer.statusChanges.sort((a: any, b: any) => b.timestamp.getTime() - a.timestamp.getTime());
                }
            });
            if (data.offers.appointment) {
                data.offers.appointment = data.offers.appointment.toDate();
            }
        }
        return data;
    }

    private addTexts(tickets: Ticket[], texts: TextObjAggregated) {
        if (tickets?.length) {
            tickets.forEach((ticket: any) => {
                ticket.hashtags.forEach((tag: any) => {
                    if (tag.descriptionId) {
                        tag.description = texts[tag.descriptionId];
                    }
                    if (tag.textId) {
                        tag.text = texts[tag.textId];
                    }
                });
            });
            return tickets;
        } else {
            return [];
        }
    }

    public async loadMoreTickets(status, ticketNoOffset) {
        return new Promise((resolve) => {
            return this.getObservableQueryOnTickets(status, ticketNoOffset).subscribe(async (newTickets) => {
                const tickets = {
                    init: [],
                    accepted: [],
                    offer: [],
                    inWork: [],
                    finished: [],
                    declined: [],
                    canceled: [],
                };
                this.addTexts(newTickets, this.texts);
                newTickets.map((ticket) => {
                    switch (ticket.status) {
                        case TicketStatus.INIT:
                            tickets.init.push(ticket);
                            break;
                        case TicketStatus.ACCEPTED:
                            tickets.accepted.push(ticket);
                            break;
                        case TicketStatus.IN_PROGRESS:
                            tickets.inWork.push(ticket);
                            break;
                        case TicketStatus.OFFERS:
                            tickets.offer.push(ticket);
                            break;
                        case TicketStatus.FINISHED:
                            tickets.finished.push(ticket);
                            break;
                        case TicketStatus.DECLINED:
                            tickets.declined.push(ticket);
                            break;
                        case TicketStatus.CANCELED:
                            tickets.canceled.push(ticket);
                            break;
                    }
                });
                resolve(this.setupTickets(tickets, true));
            });
        });
    }

    async createTicket(ticket: NewTicket) {
        const generatedTicket: TicketTemplate = await this.createTicketTemplate(
            ticket.description,
            ticket.hashtags,
            ticket.appointments,
            ticket.contact,
            ticket.flatId,
            ticket.propertyId,
            ticket.uid,
            ticket.pendingAppointment,
            ticket.disableTenantNotification,
            ticket.hideTicketFromTenant,
            ticket.target
        );

        const createdTicket: any = await this.http.post(`${environment.apiBase}tickets`, generatedTicket).toPromise();

        if (ticket.pictures && ticket.pictures.length) {
            const formData = new FormData();
            for (const file of ticket.pictures) {
                // eslint-disable-next-line no-await-in-loop
                const blob = await this.documentUtilService.downloadFileOrBlob(file);
                formData.append(blob.name, blob.file, blob.name);
            }

            await this.http.post(`${environment.apiBase}tickets/${createdTicket.id}/documents`, formData).toPromise();
        }
    }

    getCurrentInfoState(ticket: Ticket): any {
        const statusManager = [];
        for (const state of Object.keys(ticket.statusManager)) {
            const status: any = ticket.statusManager[state];
            status.status = state;
            statusManager.push(status);
        }
        return statusManager
            .filter((state) => state.value)
            .sort((a: any, b: any) => {
                return b.position - a.position;
            })[0].status;
    }

    async setOffers(ticket: any, offers?: any) {
        if (!offers) {
            offers = await this.http.get(`${environment.apiBase}offers/${ticket.id}`).toPromise();
        }

        ticket.offers = {};

        for (const offer of offers) {
            ticket.offers[offer.providerId] = offer;
        }

        return ticket;
    }

    getOffersObservable(ticketId: string, limit?: number): Observable<any> {
        const offersCollection = collection(this.firestore, `ns/${this.userService.getNamespace()}/offers`);
        let offersQuery = query(offersCollection, where('ticketId', '==', ticketId));

        if (limit) {
            offersQuery = query(offersQuery, firestoreLimit(limit));
        }

        return collectionData(offersQuery, { idField: 'id' }).pipe(
            map((offers: any[]) => {
                return offers.map((offer) => {
                    convertFirestoreDate(offer);
                    // if (offer.createdOn) {
                    //     offer.createdOn = offer.createdOn.toDate();
                    // }
                    // if (offer.updatedOn) {
                    //     offer.updatedOn = offer.updatedOn.toDate();
                    // }
                    return offer;
                });
            })
        );
    }

    async getTicketTextsByTicketAndKey(ticket: any, key?: string) {
        const texts: any = await this.apiService.get(`tickets/${ticket.id}/texts${key ? '/' + key : ''}`);

        for (const text of texts) {
            if (text) {
                ticket = this.applyTicketText(ticket, text);
            }
        }

        return ticket;
    }

    public getTicketTextsObservableByKey(key: string, ticketId?: string): Observable<any[]> {
        const ticketTextsCollection = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTexts`);
        let ticketTextsQuery = query(ticketTextsCollection, where('key', '==', key));

        if (ticketId) {
            ticketTextsQuery = query(ticketTextsQuery, where('ticketId', '==', ticketId));
        }

        return collectionData(ticketTextsQuery);
    }

    public async getTicketTextsPromiseByTicketAndKey(ticket: any, key: string): Promise<any[]> {
        const ticketTextsCollection = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTexts`);
        const ticketTextsQuery = query(
            ticketTextsCollection,
            where('ticketId', '==', ticket.id),
            where('key', '==', key)
        );

        return getDocs(ticketTextsQuery).then((querySnap) => {
            if (!querySnap.empty) {
                return querySnap.docs.map((doc) => doc.data());
            } else {
                return Promise.reject('No documents found');
            }
        });
    }

    private async getTextsByTicketNote(ticketNote: any) {
        const texts: any[] = await this.apiService.get(
            `tickets/${ticketNote.ticketId}/texts/note.${ticketNote.type}.${ticketNote.id}`
        );

        for (const text of texts) {
            if (text) {
                ticketNote = this.applyTextToTicketNote(ticketNote, text);
            }
        }
        return ticketNote;
    }

    private applyTextToTicketNote(ticketNote: any, text: any) {
        if (!ticketNote) {
            ticketNote = {};
        }

        if (!ticketNote.text || typeof ticketNote.text !== 'object') {
            ticketNote.text = {};
        }

        if (text.type === 'original') {
            ticketNote.text.original = {
                language: text.lang,
                value: text.value,
            };
            ticketNote.text[text.lang] = text.value;
        } else {
            if (!ticketNote.text[text.lang]) {
                ticketNote.text[text.lang] = text.value;
            }
        }

        return ticketNote;
    }

    public applyTicketText(object: any, text: any, index = 0) {
        const layers = text.key.split('.');

        if (!object) {
            object = {};
        }

        if (index === layers.length) {
            if (text.type === 'original') {
                object.original = {
                    language: text.lang,
                    value: text.value,
                };
                object[text.lang] = text.value;
            } else {
                if (!object[text.lang]) {
                    object[text.lang] = text.value;
                }
            }
        } else {
            object[layers[index]] = this.applyTicketText(object[layers[index]], text, index + 1);
        }

        return object;
    }

    getInitialTickets(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.INIT);
    }

    getTicketsCanceled(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.CANCELED);
    }

    private getTicketsDeclined(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.DECLINED);
    }

    private getTicketsFinished(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.FINISHED);
    }

    private getTicketsAccepted(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.ACCEPTED);
    }

    private getTicketsInWork(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.IN_PROGRESS);
    }

    private getTicketsOffer(): Observable<Ticket[]> {
        return this.getObservableQueryOnTickets(TicketStatus.OFFERS);
    }

    private async createTicketTemplate(
        description: string,
        hashtags: TicketNode[] = null,
        appointments: any = null,
        contact: any,
        flatId: string,
        propertyId: string,
        uid: string,
        pendingAppointment: boolean,
        disableTenantNotification: boolean,
        hideTicketFromTenant: boolean,
        target: string
    ): Promise<TicketTemplate> {
        const internalTags = [];
        hashtags.forEach((tag: TicketNode) => {
            internalTags.push({ id: tag.id, textId: tag.textId });
        });

        return {
            description,
            appointments,
            creator: {
                id: this.userService.user.id,
                type: 'manager',
            },
            flatId: flatId || null,
            hashtags: internalTags,
            contact,
            propertyId: propertyId || null,
            status: TicketStatus.ACCEPTED,
            uid: uid || null,
            pendingAppointment: pendingAppointment || null,
            disableTenantNotification,
            hideTicketFromTenant,
            target,
        };
    }

    public subscribeToTicket(ticketId: string): Observable<any> {
        const ticketDocRef = doc(this.firestore, `ns/${this.userService.getNamespace()}/ticketsHash/${ticketId}`);
        return docSnapshots(ticketDocRef).pipe(
            map((snapshot) => {
                const ticket = snapshot.data() as Ticket;
                if (ticket) {
                    this.ticketMapping(ticket);
                }
                return ticket;
            })
        );
    }

    private ticketMapping(data: Ticket) {
        const toDate = (value: any) => (value?.toDate ? value.toDate() : new Date(value));

        if (data.createdOn) {
            data.createdOn = toDate(data.createdOn);
        }

        if (data.offers) {
            Object.values(data.offers).forEach((offer: any) => {
                if (offer.statusChanges) {
                    offer.statusChanges = offer.statusChanges.map((status: any) => {
                        status.timestamp = toDate(status.timestamp);
                        return status;
                    });
                    offer.statusChanges.sort((a: any, b: any) => b.timestamp.getTime() - a.timestamp.getTime());
                }
            });

            if (data.offers.appointment) {
                data.offers.appointment = toDate(data.offers.appointment);
            }
        }
        return this.addTexts([data], this.texts)[0];
    }

    public async getTicketEvents(ticketId: string): Promise<any> {
        return await this.apiService.get(`histories/tickets/${ticketId}/states`);
    }

    public async getTicketNotes(ticketId: string): Promise<any[]> {
        const notes: any[] = await this.apiService.get(`tickets/${ticketId}/notes?type=all`);
        const promises = [];

        notes.sort((a, b) => {
            a = new Date(a.createdOn);
            b = new Date(b.createdOn);
            return b - a;
        });

        for (const note of notes) {
            promises.push(this.getTextsByTicketNote(note));
        }

        return (await Promise.all(promises)).filter((note) => note);
    }

    public async addTicketNote(note: any, documents: any) {
        const ticketNote = await this.apiService.post(`tickets/${note.ticketId}/notes`, note);

        const preparedDocuments = await this.documentUtilService.prepareDocuments(documents || { imgs: [], pdfs: [] });
        if (preparedDocuments.imgs.length || preparedDocuments.pdfs.length) {
            await this.documentsRequests.upload(`tickets/${ticketNote.ticketId}/notes`, ticketNote.id, [
                ...preparedDocuments.imgs,
                ...preparedDocuments.pdfs,
            ]);
        }
    }

    public getTicketNotesObservable(ticketId: string, limitCount?: number): Observable<any[]> {
        const ticketNotesRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketNotes`);
        let q = query(ticketNotesRef, where('ticketId', '==', ticketId), where('type', '==', 'tenant'));
        if (limitCount) {
            q = query(q, orderBy('createdOn', 'desc'), limit(limitCount));
        }

        return collectionData(q);
    }

    public async countTicketNotes(ticketId: string) {
        return this.elasticService.searchTicketNotes({
            size: 0,
            filter: {
                'ticketId.keyword': ticketId,
                'creator.type.keyword': 'user',
                'type.keyword': 'tenant',
            },
        });
    }

    public async hasUnreadNotes(ticketId: string) {
        const result = await this.elasticService.searchTicketNotes({
            size: 0,
            filter: {
                'ticketId.keyword': ticketId,
                'creator.type.keyword': 'user',
                'type.keyword': 'tenant',
            },
            excludes: {
                'readBy.keyword': this.userService.user.id,
            },
        });

        return !!result.total;
    }

    public async hasUnreadOffers(ticketId: string) {
        const result = await this.elasticService.searchOffers({
            size: 0,
            filter: {
                'ticketId.keyword': ticketId,
            },
            excludes: {
                'readBy.keyword': this.userService.user.id,
            },
        });

        return !!result.total;
    }

    public async createOffer(offer: any): Promise<void> {
        await firstValueFrom(this.http.post(`${environment.apiBase}offers`, offer));
    }

    public async updateOffer(offer: any): Promise<void> {
        await firstValueFrom(this.http.put(`${environment.apiBase}offers/${offer.id}`, offer));
    }

    public async cancelOffer(offerId: string): Promise<void> {
        await firstValueFrom(this.http.delete(`${environment.apiBase}offers/${offerId}`));
    }

    acceptTicket(ticketId: string): Promise<any> {
        const update = {
            id: ticketId,
            status: TicketStatus.ACCEPTED,
        };
        return this.updateTicketStatus(update);
    }

    setTicketInWork(ticketId: string): Promise<any> {
        const update = {
            id: ticketId,
            status: TicketStatus.IN_WORK,
        };
        return this.updateTicketStatus(update);
    }

    finishTicket(ticketId: string, comment?: string) {
        const update = {
            id: ticketId,
            status: TicketStatus.FINISHED,
            completedComment: comment || null,
        };
        return this.updateTicketStatus(update);
    }

    cancelTicket(ticketId: string, comment?: string) {
        const update = {
            id: ticketId,
            status: TicketStatus.CANCELED,
            completedComment: comment || null,
        };

        return this.updateTicketStatus(update);
    }

    declineTicket(ticketId: string, comment?: string): Promise<void> {
        const update = {
            id: ticketId,
            status: TicketStatus.DECLINED,
            completedComment: comment || null,
        };
        return this.updateTicketStatus(update);
    }
    changeManager(ticketId: string, managerId: string) {
        const update = {
            id: ticketId,
            ticketManager: managerId,
        };
        return this.updateTicketStatus(update);
    }

    updateTicketStatus(update: any): Promise<any> {
        return this.http.put(`${environment.apiBase}tickets/${update.id}`, update).toPromise();
    }

    terminate() {
        this.initialTickets$.next(null);
        this.acceptedTickets$.next(null);
        this.inWorkTickets$.next(null);
        this.ticketsWithOffers$.next(null);
        this.finishedTickets$.next(null);
        this.declinedTickets$.next(null);
        this.canceledTickets$.next(null);
        this.ticketsCount$.next(this.states);
        this.sub.unsubscribe();
    }

    public async getTicketByTicketNo(ticketNo: string): Promise<Ticket> {
        const ticketRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketsHash`);
        const querySnap = await getDocs(query(ticketRef, where('ticketNo', '==', Number(ticketNo)), limit(1)));

        if (!querySnap.empty) {
            return querySnap.docs[0].data() as Ticket;
        }
        return Promise.reject();
    }

    public async getOffersByProviderId(providerId: string, excludeStatus: OfferStatus[]): Promise<Ticket[]> {
        const querySnap = await getDocs(
            query(
                collection(this.firestore, `ns/${this.userService.getNamespace()}/offers`),
                where('providerId', '==', providerId)
            )
        );

        return querySnap.docs
            .map((doc) => {
                const data = doc.data();
                return excludeStatus?.includes(data.status) ? null : (data as Ticket);
            })
            .filter((offer): offer is Ticket => offer !== null);
    }

    public getTicketOfferObservable(ticketId: string): Observable<any[]> {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/offers`);
        const q = query(collectionRef, where('ticketId', '==', ticketId));
        return collectionData(q);
    }

    public getTicketAppointmentsObservable(ticketId: string): Observable<any[]> {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/appointments`);
        const q = query(collectionRef, where('ticketId', '==', ticketId));
        return collectionData(q);
    }

    public async deleteComment(ticketId: string, noteId: any) {
        await this.apiService.delete(`tickets/${ticketId}/notes/${noteId}`);
    }
}
