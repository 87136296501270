import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopupService } from '../../services/popup/popup.service';
import { UserService } from 'src/app/services/user/user.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { PushNotificationService } from '../../services/push-notification/push-notification.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.page.html',
    styleUrls: ['./main.page.scss'],
})
export class MainPage implements OnInit, OnDestroy {
    notifications: number = 0;
    subscriptions = [];

    constructor(
        private pushNotificationService: PushNotificationService,
        private popupService: PopupService,
        public userService: UserService,
        private notificationsService: NotificationsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.pushNotificationService.messages$.subscribe((message) => {
            if (message) {
                this.popupService.showToast(message);
            }
        });

        this.subscriptions.push(
            this.notificationsService.observeUnreadNotifications().subscribe((notifications) => {
                this.notifications = notifications.length;
            })
        );
    }

    unsubscribeAll(): void {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }

        this.subscriptions = [];
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }
}
