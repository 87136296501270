import { DocumentsRequests } from '../../util/documents-requests';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { FileDocument } from '../../models/file-document';
import { environment } from '../../../environments/environment';
import { PhotoViewerComponent } from '../../modals/photo-viewer/photo-viewer.component';
import { PdfComponent } from '../../modals/pdf-viewer/pdf-viewer.component';
import { TranslateService } from '@ngx-translate/core';
import { DesktopFilePickerService } from '../desktop-file-picker/desktop-file-picker.service';
import { Browser, OpenOptions } from '@capacitor/browser';
import { Document } from '../../models/document';
import { Capacitor } from '@capacitor/core';

const b64Prefix: string = 'data:image/jpeg;base64,';
const b64PngPrefix: string = 'data:image/png;base64,';
const b64JpgPrefix: string = 'data:image/jpg;base64,';
const b64PdfPrefix: string = 'data:application/pdf;base64,';

/*
  Generated class for the DocumentsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
    providedIn: 'root',
})
export class DocumentUtilService {
    constructor(
        public http: HttpClient,
        private storage: Storage,
        private documentsRequests: DocumentsRequests,
        private modalController: ModalController,
        private actSheetCtrl: ActionSheetController,
        private translate: TranslateService,
        private desktopFilePicker: DesktopFilePickerService
    ) {}

    async openUploadOptions(fileTypes: string, camera = true): Promise<FileDocument[]> {
        let buttonTextAlbum: string;
        if (fileTypes.includes('image') && fileTypes.includes('pdf')) {
            buttonTextAlbum = this.translate.instant('upload.open-files');
        } else if (fileTypes.includes('image')) {
            buttonTextAlbum = this.translate.instant('upload.open-album');
        } else {
            buttonTextAlbum = this.translate.instant('upload.open-pdf');
        }

        let files: FileDocument[] = [];

        const buttons = [];
        if (camera && Capacitor.isNativePlatform()) {
            buttons.push({
                text: this.translate.instant('upload.open-camera'),
                handler: async () => {
                    const photo: FileDocument = await this.openCamera();
                    files.push(photo);
                },
            });
        }

        if (Capacitor.getPlatform() === 'ios') {
            // ios opens own select menu for camera, gallery, document picker
            buttons.push({
                text: buttonTextAlbum,
                handler: async () => {
                    files = await this.desktopFilePicker.pickMultiple(fileTypes);
                },
            });
        } else {
            buttons.push({
                text: buttonTextAlbum,
                handler: async () => {
                    files = await this.desktopFilePicker.pickMultiple(fileTypes);
                },
            });
        }

        buttons.push({
            text: this.translate.instant('upload.cancel'),
            role: 'cancel',
        });

        const actionSheet = await this.actSheetCtrl.create({
            buttons,
        });
        await actionSheet.present();
        await actionSheet.onDidDismiss();
        return files;
    }

    private async getImgFromDevice(sourceType: CameraSource): Promise<Document> {
        try {
            const photo: Photo = await Camera.getPhoto({
                quality: 50,
                resultType: CameraResultType.Base64,
                source: sourceType,
                correctOrientation: true,
                width: 800, // Adjust as needed
            });

            const img = `data:image/jpeg;base64,${photo.base64String}`;

            return {
                name: `${new Date().getTime().toString()}.jpeg`,
                file: img,
                mimetype: 'image/jpeg',
            };
        } catch (error) {
            console.error('Error capturing image:', error);
            throw error;
        }
    }

    public async openCamera(): Promise<FileDocument> {
        return await this.getImgFromDevice(CameraSource.Photos);
    }

    async getCroppedImg(targetWidth: number, targetHeight: number, sourceType: CameraSource): Promise<string> {
        try {
            const photo: Photo = await Camera.getPhoto({
                quality: 50,
                resultType: CameraResultType.Base64,
                source: sourceType,
                correctOrientation: true,
                width: targetWidth,
                height: targetHeight,
            });

            return `data:image/jpeg;base64,${photo.base64String}`;
        } catch (error) {
            console.error('Error cropping image:', error);
            throw error;
        }
    }

    async documentUpload(doc: any, path: string, type: string): Promise<string> {
        try {
            const blob = typeof doc === 'string' ? await (await fetch(doc)).blob() : doc;
            const storageRef = ref(this.storage, path);
            await uploadBytes(storageRef, blob);
            return await getDownloadURL(storageRef);
        } catch (error) {
            console.error('Error uploading document:', error);
            throw error;
        }
    }

    async openDocument(document): Promise<any> {
        if (document.mimetype.startsWith('image')) {
            if (Capacitor.isNativePlatform()) {
                const modal = await this.modalController.create({
                    component: PhotoViewerComponent,
                    componentProps: {
                        images: [document],
                        index: 0,
                        imageDeleteAllowed: false,
                    },
                });
                await modal.present();
            } else {
                this.openInBrowser(document.file);
            }
            return null;
        } else if (document.mimetype.startsWith('application/pdf')) {
            if (Capacitor.isNativePlatform()) {
                const modal = await this.modalController.create({
                    component: PdfComponent,
                    componentProps: {
                        document,
                    },
                    cssClass: 'fullscreen',
                });
                await modal.present();
                return null;
            } else {
                this.openInBrowser(document.file);
            }
        } else if (document.file.startsWith('data:')) {
            const pdfWindow = window.open('');
            pdfWindow.document.write(`<iframe width="100%" height="100%" src="${encodeURI(document.file)}"></iframe>`);
            return null;
        } else if (
            document.file.startsWith('https') &&
            document.file.indexOf(environment.firebase.storageBucket) !== -1
        ) {
            this.openInBrowser(document.file);
            return null;
        } else {
            console.error('Unknown Document', document.file);
            return null;
        }
    }

    openInBrowser(url: string): void {
        const openOption: OpenOptions = {
            url: url,
            windowName: '_system',
        };
        Browser.open(openOption);
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    downsizeImage(imageAsDataUrl: string, type, width: number, height: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageAsDataUrl;
            image.onload = () => {
                const aspectSize = this.calculateAspectRatioFit(image.width, image.height, width, height);

                const canvas = document.createElement('canvas');
                canvas.width = aspectSize.width;
                canvas.height = aspectSize.height;

                const canvas2DContext = canvas.getContext('2d');
                canvas2DContext.drawImage(image, 0, 0, aspectSize.width, aspectSize.height);
                resolve(canvas2DContext.canvas.toDataURL(type));
            };
        });
    }

    async prepareDocuments(documents) {
        const imgs = [];
        const pdfs = [];

        for (const img of documents.imgs) {
            if (!img.isAlreadyUploaded) {
                imgs.push(this.downloadFileOrBlob(img));
            }
        }
        for (const pdf of documents.pdfs) {
            if (!pdf.isAlreadyUploaded) {
                pdfs.push(this.downloadFileOrBlob(pdf));
            }
        }
        return { imgs: await Promise.all(imgs), pdfs: await Promise.all(pdfs) };
    }

    async downloadFileOrBlob(file) {
        if (file && typeof file.file === 'string') {
            const blob = await (
                await fetch(file.file, {
                    method: 'GET',
                })
            ).blob();

            return { name: file.name, file: blob };
        }
        return file;
    }

    async deleteFilesFromObject(objectId: string, objectKey: string, oldDocuments: any, newDocuments: any) {
        if ((oldDocuments?.pdfs || oldDocuments?.pdfs) && (newDocuments?.pdfs || newDocuments?.imgs)) {
            const fileToDelete = this.getDeletedFile(oldDocuments, newDocuments);

            if (fileToDelete) {
                const type = fileToDelete.mimetype.includes('pdf') ? 'pdfs' : 'imgs';
                for (let i = 0; i < oldDocuments[type].length; i++) {
                    if (oldDocuments[type][i].name === fileToDelete.name) {
                        // eslint-disable-next-line no-await-in-loop
                        const result: any = await this.documentsRequests.delete(objectKey, objectId, type, i);

                        // eslint-disable-next-line no-await-in-loop
                        await this.deleteFilesFromObject(objectId, objectKey, result.documents, newDocuments);
                    }
                }
            }
        }
    }

    private getDeletedFile(oldDocuments: any, newDocuments: any) {
        const mergedTypesOld = [...oldDocuments.pdfs, ...oldDocuments.imgs];
        const mergedTypesNew = [...newDocuments.pdfs, ...newDocuments.imgs];

        const deletedFiles = mergedTypesOld.filter(
            (oldDoc) =>
                !mergedTypesNew.find((newDoc) => newDoc.name === oldDoc.name && newDoc.mimetype === oldDoc.mimetype)
        );
        return deletedFiles[0];
    }
}
