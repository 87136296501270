import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { DataService } from './services/data/data.service';
import { LanguageService } from './services/language/language.service';
import { ThemeService } from './services/theme/theme.service';
import { PopupService } from './services/popup/popup.service';
import { AppPlatformService } from './services/app-platform/app-platform.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { UpdateService } from './services/ota/update.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { LegalDocumentsAcceptModalComponent } from './modals/legal-documents/legal-documents-accept-modal.component';
import { LegalDocumentsService } from './services/legal-documents/legal-documents.service';
import { NamespaceService } from './services/namespace/namespace.service';
import { OriginService } from './services/origin/origin.service';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { StatusBar } from '@capacitor/status-bar';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Manager } from './models/manager';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    private initialRoute;
    appWidth: number;

    private legalDocumentsModalRef: HTMLIonModalElement;
    private networkStatusListener: PluginListenerHandle;
    private subscriptions: Subscription[] = [];

    constructor(
        private platform: Platform,
        private navController: NavController,
        public auth: AuthService,
        private userService: UserService,
        private dataService: DataService,
        private languageService: LanguageService,
        private themeService: ThemeService,
        private popupService: PopupService,
        private translate: TranslateService,
        private location: Location,
        private appPlatform: AppPlatformService,
        private updateService: UpdateService,
        private modalController: ModalController,
        private legalDocumentsService: LegalDocumentsService,
        private namespaceService: NamespaceService,
        private originService: OriginService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.platform.ready();
        const networkStatus = await Network.getStatus();
        if (!networkStatus.connected) {
            await this.handleInitializationError();
        } else {
            await this.auth.initialize();
            if (!Capacitor.isNativePlatform()) {
                this.translate.onLangChange.subscribe(() => {
                    document.title = this.translate.instant('TAB_TITLE');
                });
            }
            await this.initializeApp();
        }
    }

    private async initializeApp(): Promise<void> {
        const appInfo: AppInfo = await this.initializeNativePlatform();
        await this.originService.initialize(appInfo);
        await this.themeService.setTheme();

        if (!Capacitor.isNativePlatform() && !environment.production) {
            this.initialRoute = this.location.path();
            await this.navController.navigateRoot('start');
        }
        try {
            await this.handleWeblogin();
            await this.handleUserLogin();
        } catch (err) {
            await this.handleInitializationError();
        } finally {
            await SplashScreen.hide();
        }
        await this.showLegalDocumentsModal();
    }

    private async initializeNativePlatform(): Promise<AppInfo> {
        let appInfo: AppInfo = { name: null, id: null, build: null, version: null };
        if (Capacitor.isNativePlatform()) {
            try {
                appInfo = await App.getInfo();
                await StatusBar.setOverlaysWebView({ overlay: false });
                await StatusBar.show();
                await BarcodeScanner.installGoogleBarcodeScannerModule();
            } catch (error) {
                console.error('Error setting status bar style:', error);
            } finally {
                this.updateService.initialize().catch(console.error);
            }
        }
        return appInfo;
    }

    private async handleUserLogin(): Promise<void> {
        const manager: Manager = await this.auth.getCurrentUser();
        if (manager) {
            const oldTokenResult = await this.userService.firebaseUser.getIdTokenResult();
            await this.auth.login('', '', oldTokenResult.token);
            await this.dataService.initializeEssentialAppData();
            await this.dataService.initializeAppData();
            // #TODO why user is fetched again?
            const user: Manager = await this.userService.getUser();
            if (user) {
                this.translate.resetLang(user.language || this.translate.currentLang);
                await this.userService.setLanguage(user.language || this.translate.currentLang, true);
                if (user.language) {
                    this.translate.use(user.language);
                }
            } else {
                this.translate.resetLang(this.translate.currentLang);
            }
            await this.goToApp(this.initialRoute);
            await this.languageService.initialize();
        } else {
            await this.goToLogin();
        }
    }

    private async handleInitializationError(): Promise<void> {
        const networkStatus = await Network.getStatus();
        if (Capacitor.isNativePlatform() && networkStatus.connected) {
            await this.navController.navigateRoot('start');
            await this.waitForNetwork();
            await this.goToLogin();
        } else {
            await this.goToLogin();
        }
    }

    private async waitForNetwork(): Promise<void> {
        const offlineToast = await this.popupService.showToast(
            'Offline',
            true,
            { duration: -1 } // permanent
        );
        this.networkStatusListener = await Network.addListener('networkStatusChange', async (status) => {
            console.info('Network status changed', status);
            if (status.connected) {
                await offlineToast.dismiss();
                await this.networkStatusListener.remove();
            }
        });
    }

    /**
     * Check if currently logged in user has accepted the latest legal documents
     *
     * Displaying a popup if there are newer documents
     *
     */
    private async showLegalDocumentsModal(): Promise<void> {
        this.subscriptions.push(
            combineLatest([
                this.legalDocumentsService.getLatestLegalDocumentsObservable(),
                this.userService.user$.asObservable(),
                this.dataService.appDataInitialized$.asObservable(),
            ] as [Observable<any>, Observable<Manager>, Observable<boolean>]).subscribe(
                ([latestLegalDocuments, propertyManager, appDataInitialized]) => {
                    (async () => {
                        const domain = await this.namespaceService.getDomain();
                        latestLegalDocuments = latestLegalDocuments[domain];
                        if (latestLegalDocuments && propertyManager && appDataInitialized) {
                            const documentNames = ['privacyPolicy', 'termsOfUse'];

                            /* per default all documents are required to be accepted
                           exclusions are possible with this array */
                            const excludedDocuments = [];

                            // check if the user has accepted the latest versions of the legal documents
                            for (const documentName of documentNames) {
                                if (
                                    propertyManager.legalDocuments[domain] &&
                                    propertyManager.legalDocuments[domain][documentName]?.version ===
                                        latestLegalDocuments[documentName].version
                                ) {
                                    excludedDocuments.push(documentName);
                                }
                            }

                            // Check if there is at least one legal document to accept
                            if (excludedDocuments.length < documentNames.length) {
                                if (this.legalDocumentsModalRef) {
                                    // close previous dialog if there is already one opened
                                    this.legalDocumentsModalRef.dismiss();
                                    this.legalDocumentsModalRef = null;
                                }

                                this.legalDocumentsModalRef = await this.modalController.create({
                                    component: LegalDocumentsAcceptModalComponent,
                                    componentProps: {
                                        excludedDocuments, // exclude given documents
                                    },
                                    backdropDismiss: false,
                                    keyboardClose: false,
                                    cssClass: 'small-modal',
                                });

                                await this.legalDocumentsModalRef.present();

                                // get result of modal after it was closed
                                const modalResult = await this.legalDocumentsModalRef.onDidDismiss();

                                if (modalResult?.data?.acceptedLegalDocuments) {
                                    await this.legalDocumentsService.setLegalDocumentsAccepted(
                                        modalResult.data.acceptedLegalDocuments
                                    );
                                }
                            }
                        }
                    })();
                }
            )
        );
    }

    private async goToLogin(): Promise<void> {
        await this.languageService.initialize();
        if (!this.translate.currentLang) {
            const browserLang = navigator.language.substr(0, 2);
            this.translate.use(browserLang);
        }
        await this.navController.navigateRoot('login');
    }

    private async handleWeblogin(): Promise<void> {
        let token: string;
        if (this.initialRoute && this.initialRoute.includes('token')) {
            token = this.initialRoute.slice(7);
        } else {
            const params = new URLSearchParams(window.location.search.slice(1));
            token = params.get('token');
        }
        this.appPlatform.setAppIsDashboard(!!token);
        if (token) {
            try {
                await this.auth.loginWithCustomToken(token);
            } catch (error) {
                console.error(error);
            }
        }
    }

    private async goToApp(targetRoute: string): Promise<void> {
        if (!targetRoute || targetRoute === '/start' || targetRoute === '/login' || targetRoute.startsWith('?token')) {
            await this.navController.navigateRoot('/main');
        } else {
            await this.navController.navigateRoot(targetRoute);
        }
    }
}
