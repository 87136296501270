import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
    @Input() title;
    @Input() left: string;
    @Input() leftBack: boolean;
    @Input() right: string;
    @Input() leftText: string;
    @Input() rightText: string;
    @Input() showNotificationBlocker = false;
    @Input() theme: string;
    @Output() leftClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() rightClicked: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('backButton') backButton;

    /**
     * The ion back button has some strange behaviour sometimes. It's visible but nothing happens
     * on click. Therefore we just use a hidden one to make sure we can emit the click
     * event regardless
     */
    leftButtonClicked(event) {
        if (this.leftBack && this.backButton) {
            this.backButton.el.click();
        }
        this.leftClicked.emit(event);
    }
}
