import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    public form: UntypedFormGroup;

    constructor(
        public modalController: ModalController,
        private fb: UntypedFormBuilder,
        private authProvider: AuthService,
        private translate: TranslateService,
        private userService: UserService,
        private alertController: AlertController
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', [Validators.required]],
        });
        if (this.userService.user) {
            this.email.setValue(this.userService.user.email);
        }
    }

    updateUserPassword() {
        this.authProvider
            .sendPasswordResetMail(this.email.value)
            .then(async () => {
                const alert = await this.alertController.create({
                    header: this.translate.instant('login.reset-mail.successful'),
                    message: this.translate.instant('login.reset-mail-sent'),
                    buttons: [this.translate.instant('login.ok')],
                });
                await alert.present();
                await this.modalController.dismiss();
            })
            .catch(async (err) => {
                console.error('Change Password failed', err);
                let text = 'login.reset-mail-error';
                switch (err.status) {
                    case 404:
                        text = 'login.email-not-exists';
                        break;
                }
                const alert = await this.alertController.create({
                    header: this.translate.instant('login.reset-mail.error'),
                    message: this.translate.instant(text),
                    buttons: [this.translate.instant('login.ok')],
                });
                await alert.present();
            });
    }

    get email() {
        return this.form.get('email');
    }
}
