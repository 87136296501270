import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '../../models/property';
import { Flat } from '../../models/flat';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../models/user';
import { NamespaceService } from 'src/app/services/namespace/namespace.service';
import { CustomTranslationPipe } from '../custom-translation/custom-translation';
import { DomSanitizer } from '@angular/platform-browser';
import { Organisation } from '../../models/organisation';

@Pipe({
    name: 'objectDisplayFormat',
})
export class ObjectDisplayFormatPipe implements PipeTransform {
    constructor(
        private translate: TranslateService,
        private customTranslation: CustomTranslationPipe,
        private ns: NamespaceService,
        private sanitizer: DomSanitizer
    ) {}

    transform(objectToFormat: Flat | Property | User | Organisation) {
        if (!objectToFormat) {
            return '';
        } else if ((objectToFormat as User).lastname || (objectToFormat as User).firstname) {
            return this.getUserFormat(objectToFormat);
        } else if ((objectToFormat as Flat).propertyId) {
            return this.getFlatFormat(objectToFormat);
        }

        return this.getPropertyFormat(objectToFormat);
    }

    getUserFormat(obj: any) {
        const user = obj as User;
        return `${user.firstname ? user.firstname + ' ' : ''}${user.lastname}${
            user.organisationName ? ` | ${user.organisationName}` : ''
        }`;
    }

    getFlatFormat(obj: any) {
        const flat = obj as Flat;
        const flatFormat = this.ns?.ticketConfig?.formats?.managerApp?.flat;

        if (flatFormat) {
            const extraFields = this.ns.flatTypeConfig[flat.type]?.visibleFields || [];

            const placeholders = this.getPlaceholders(flat);

            for (const extraField of extraFields) {
                placeholders[extraField] = flat[extraField] || '';
            }

            return this.customTranslation.fillPlaceholders(flatFormat, placeholders);
        }

        return `${flat.propertyname ? `${flat.propertyname} |` : ''} ${flat.street} | ${flat.zipcode} ${
            flat.city
        } | ${this.translate.instant(`flats.flatTypes.${flat.type}`)}${flat.floor ? ` | ${flat.floor}` : ''}${
            flat.reference ? ` | ${flat.reference}` : ''
        }`;
    }

    getPropertyFormat(obj: any) {
        const property = obj as Property;
        const propertyFormat = this.ns?.ticketConfig?.formats?.managerApp?.property;

        if (propertyFormat) {
            return this.customTranslation.fillPlaceholders(propertyFormat, this.getPlaceholders(property));
        }

        return `${property.name ? `${property.name} |` : ''} ${property.street} | ${property.zipcode} ${property.city}`;
    }

    private getPlaceholders(model: any) {
        const modelFields = Object.keys(model).filter(
            (key) => !model[key] || !['object', 'boolean'].includes(typeof model[key])
        );
        const placeholders = {};

        for (const field of modelFields) {
            placeholders[field.toUpperCase()] = model[field] || '';
        }

        return placeholders;
    }

    getOrganisationFormat(obj: any) {
        const organisation = obj as Organisation;
        const organisationFormat = this.ns?.ticketConfig?.formats?.managerApp?.organisation;

        if (organisationFormat) {
            return this.customTranslation.fillPlaceholders(organisationFormat, this.getPlaceholders(organisation));
        }

        return `${organisation.reference ? `${organisation.reference} | ` : ''}
        ${organisation.name ? `${organisation.name} |` : ''}
        ${organisation.street}, ${organisation.zipcode} ${organisation.city}`;
    }

    public formatOrganisation(organisation: any) {
        organisation.formattedLabel = this.sanitizer.bypassSecurityTrustHtml(this.getOrganisationFormat(organisation));

        return organisation;
    }

    public formatProperty(property: any) {
        if (this.ns.ticketConfig?.formats?.managerApp?.property) {
            property.formattedLabel = this.sanitizer.bypassSecurityTrustHtml(this.transform(property));
        }

        return property;
    }

    public formatFlat(flat: any) {
        if (this.ns.ticketConfig?.formats?.managerApp?.flat) {
            flat.formattedLabel = this.sanitizer.bypassSecurityTrustHtml(this.transform(flat));
        }

        return flat;
    }
}
