export function convertFirestoreDate(json: any) {
    for (const field of Object.getOwnPropertyNames(json)) {
        if (json[field] && typeof json[field] === 'object' && typeof json[field].toDate === 'function') {
            json[field] = json[field].toDate();
        } else if (json[field] && typeof json[field] === 'object') {
            convertFirestoreDate(json[field]);
        }
    }
}

export function flatArray(array, depth = 1): any[] {
    return array.flat(depth);
}
