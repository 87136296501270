import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { OpenOptions as BrowserOpenOptions } from '@capacitor/browser';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {
    callPhone(phoneNumber: string) {
        window.location.href = `tel:${phoneNumber.replace(/\s/g, '')}`;
    }

    sendMail(emailAddress: string) {
        window.location.href = `mailto:${emailAddress.replace(/\s/g, '')}`;
    }

    open(url: string, target = '_system') {
        const openOption: BrowserOpenOptions = {
            url: url,
            windowName: target,
        };
        return Browser.open(openOption);
    }
}
