import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-html-alert-modal',
    templateUrl: './html-ready-alert.component.html',
    styleUrls: ['./html-ready-alert.component.scss'],
})
export class HtmlReadyAlertComponent implements OnInit {
    @Input() translatedMessage: string;
    @Input() title: string;
    @Input() info: boolean = false;
    safeTranslatedMessage: SafeHtml;

    constructor(
        private modalController: ModalController,
        private sanitizer: DomSanitizer
    ) {}

    async ngOnInit() {
        this.safeTranslatedMessage = this.sanitizer.bypassSecurityTrustHtml(this.translatedMessage);
    }

    confirm(result: boolean) {
        this.modalController.dismiss(result);
    }
}
