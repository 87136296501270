import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { TopbarSearchComponent } from './topbar-search.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [TopbarSearchComponent],
    exports: [TopbarSearchComponent],
})
export class TopbarSearchComponentModule {}
