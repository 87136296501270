import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainPage } from './pages/main/main.page';
import { LoginPage } from './pages/login/login.page';
import { StartPage } from './pages/start/start.page';
import { ModuleGuard } from './guards/moduleguard.service';
import { NamespaceChooserPage } from './pages/namespace-chooser/namespace-chooser.page';

const routes: Routes = [
    { path: '', redirectTo: 'start', pathMatch: 'full' },
    {
        path: 'start',
        component: StartPage,
        loadChildren: () => import('./pages/start/start.module').then((m) => m.StartPageModule),
    },
    {
        path: 'login',
        component: LoginPage,
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'namespace-chooser',
        component: NamespaceChooserPage,
        loadChildren: () =>
            import('./pages/namespace-chooser/namespace-chooser.module').then((m) => m.NamespaceChooserPageModule),
    },
    {
        path: 'main',
        component: MainPage,
        children: [
            {
                path: '',
                redirectTo: 'tickets',
                pathMatch: 'full',
                data: {
                    fallbackURL: 'main/bulletinboard',
                    requiredModule: ['tickets', 'handover'],
                },
            },
            { path: '', redirectTo: 'profile', pathMatch: 'full' },
            {
                path: 'tickets',
                canActivate: [ModuleGuard],
                data: {
                    fallbackURL: 'main/bulletinboard',
                    requiredModule: ['tickets', 'handover'],
                },
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./pages/main/tickets/tickets.module').then((m) => m.TicketsPageModule),
                    },
                    {
                        path: 'detail/:ticketNo',
                        loadChildren: () =>
                            import('./pages/main/tickets/ticket-details/ticket-details.module').then(
                                (m) => m.TicketDetailsPageModule
                            ),
                    },
                ],
            },
            {
                path: 'bulletinboard',
                canActivate: [ModuleGuard],
                data: {
                    requiredModule: 'communication',
                    fallbackURL: 'main/search',
                },
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./pages/main/bulletinboard/bulletinboard.module').then(
                                (m) => m.BulletinboardPageModule
                            ),
                    },
                ],
            },
            {
                path: 'search',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./pages/main/search/search.module').then((m) => m.SearchPageModule),
                    },
                    {
                        path: 'property/:propertyId',
                        loadChildren: () =>
                            import('./pages/main/search/property/property.module').then((m) => m.PropertyPageModule),
                    },
                    {
                        path: 'flat/:flatId',
                        loadChildren: () =>
                            import('./pages/main/search/flat/flat.module').then((m) => m.FlatPageModule),
                    },
                    {
                        path: 'tenant/:userId',
                        loadChildren: () =>
                            import('./pages/main/search/tenant/tenant.module').then((m) => m.TenantPageModule),
                    },
                    {
                        path: 'group/:groupId',
                        loadChildren: () =>
                            import('./pages/main/search/group/group.module').then((m) => m.GroupPageModule),
                    },
                    {
                        path: 'organisation/:organisationId',
                        loadChildren: () =>
                            import('./pages/main/search/organisation/organisation.module').then(
                                (m) => m.OrganisationModule
                            ),
                    },
                    {
                        path: 'service-provider/:serviceProviderId',
                        children: [
                            {
                                path: '',
                                loadChildren: () =>
                                    import('./pages/main/search/service-provider/service-provider.module').then(
                                        (m) => m.ServiceProviderPageModule
                                    ),
                            },
                            {
                                path: 'tickets/list',
                                loadChildren: () =>
                                    import(
                                        './pages/main/search/service-provider/tickets-offers-list/list.page.module'
                                    ).then((m) => m.ListPageModule),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'news',
                loadChildren: () => import('./pages/main/news/news.module').then((m) => m.NewsPageModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./pages/main/profile/profile.module').then((m) => m.ProfilePageModule),
            },
        ],
    },
    {
        path: 'create',
        loadChildren: () => import('./pages/main/create/create.module').then((m) => m.CreatePageModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
