import { Component, OnInit } from '@angular/core';
import { NamespaceService } from '../../services/namespace/namespace.service';
import { UserService } from '../../services/user/user.service';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';

@Component({
    selector: 'app-start',
    templateUrl: './start.page.html',
    styleUrls: ['./start.page.scss'],
})
export class StartPage implements OnInit {
    secondarySplashLogo: string;
    isNativePlatform: boolean = Capacitor.isNativePlatform();
    hasNetwork: Promise<ConnectionStatus> = Network.getStatus();

    constructor(
        private namespaceService: NamespaceService,
        public userService: UserService
    ) {}

    async ngOnInit(): Promise<void> {
        this.secondarySplashLogo = await this.namespaceService.getNsSplashLogo();
    }
}
