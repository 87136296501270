import { UntypedFormControl } from '@angular/forms';

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
export class CustomEmailValidator {
    static emailFormat(control: UntypedFormControl): { [error: string]: any } {
        if (control?.value) {
            const sameValue = control.value.trim();

            if (sameValue !== control.value) {
                control.setValue(sameValue);
                return emailPattern.test(sameValue) ? null : { emailFormat: { valid: false } };
            } else {
                if (control.value.length) {
                    return emailPattern.test(control.value) ? null : { emailFormat: { valid: false } };
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
}
