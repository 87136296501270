import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';
import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { DocumentsRequests } from '../../util/documents-requests';
import { UserService } from '../user/user.service';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { BulletinBoardEntry } from '../../models/bulletin-board-entry';
import { BaseDataService } from '../../util/base-data-service';
import { SearchEntity } from '../../models/search-entity.enum';
import { map, switchMap } from 'rxjs/operators';
import { AppPlatformService } from '../app-platform/app-platform.service';
import { convertFirestoreDate } from 'src/app/util/util';
import { ElasticService, SearchParams } from '../elastic/elastic.service';
import { Capacitor } from '@capacitor/core';
import {
    collection,
    collectionData,
    doc,
    docData,
    Firestore,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    QuerySnapshot,
    startAfter,
    where,
} from '@angular/fire/firestore';

export interface RecipientCounts {
    appReceivers: number;
    emailReceivers: number;
    postReceivers: number;
}

@Injectable({
    providedIn: 'root',
})
export class BlackboardService implements BaseDataService {
    private MESSAGE_LOAD_STEP = 10;
    private subs: Subscription[] = [];

    broadcastMessages$: BehaviorSubject<BulletinBoardEntry[]> = new BehaviorSubject<BulletinBoardEntry[]>(null);
    propertyMessages$: BehaviorSubject<BulletinBoardEntry[]> = new BehaviorSubject<BulletinBoardEntry[]>(null);
    flatMessages$: BehaviorSubject<BulletinBoardEntry[]> = new BehaviorSubject<BulletinBoardEntry[]>(null);
    userMessages$: BehaviorSubject<BulletinBoardEntry[]> = new BehaviorSubject<BulletinBoardEntry[]>(null);

    private globalBlackboardMessagesListener: Subscription = new Subscription();

    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private appPlatform: AppPlatformService,
        private apiService: ApiService,
        private documentsRequests: DocumentsRequests,
        private documentUtilService: DocumentUtilService,
        private elasticService: ElasticService
    ) {
        if (!Capacitor.isNativePlatform && !this.appPlatform.getAppIsDashboard()) {
            this.MESSAGE_LOAD_STEP = 20;
        }
    }

    async searchBlackboards(searchParams: SearchParams) {
        const result = await this.elasticService.searchBlackboard(searchParams);

        const observables = result.ids.map((id) => {
            const docRef = doc(this.firestore, `ns/${this.userService.getNamespace()}/blackboard/${id}`);
            return docData(docRef);
        });

        return {
            ids: result.ids,
            total: result.total,
            values: !observables.length
                ? of([])
                : combineLatest(observables).pipe(
                      map((values: any) => {
                          const saneValues = values.filter((val) => Boolean(val));

                          for (const id of result.ids) {
                              if (!saneValues.some((val) => val.id === id)) {
                                  console.warn(`Warning: Found id "${id}" via search but not in DB!!!`);
                              }
                          }

                          for (const value of saneValues) {
                              convertFirestoreDate(value);
                          }

                          return saneValues;
                      })
                  ),
        };
    }

    async initialize() {
        const messageTypes: { [key: string]: BehaviorSubject<BulletinBoardEntry[]> } = {
            [SearchEntity.BROADCAST]: this.broadcastMessages$,
            [SearchEntity.PROPERTY]: this.propertyMessages$,
            [SearchEntity.FLAT]: this.flatMessages$,
            [SearchEntity.USER]: this.userMessages$,
        };

        // Listen for changes in blackboard collection (new documents, updated documents and removed documents)
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/blackboard`);
        const q = query(collectionRef, orderBy('createdOn', 'desc'), limit(1));
        this.globalBlackboardMessagesListener = collectionData(q).subscribe(async (snapChanges) => {
            // wait for elastic upsert #TODO: question time out
            await new Promise((resolve) => setTimeout(resolve, 1500));
            const results = await Promise.all(
                Object.keys(messageTypes).map(async (messageType) => {
                    const filter = { 'type.keyword': messageType };

                    if (messageType !== SearchEntity.BROADCAST) {
                        Object.assign(filter, {
                            ...filter,
                            'propertyManagerIds.keyword': [this.userService.user.id],
                        });
                    }
                    const searchParams: SearchParams = {
                        searchString: '',
                        filter,
                        rangeFilter: {
                            validTo: {
                                gte: new Date().toISOString(),
                            },
                        },
                        excludes: { showOnBlackboard: false, active: false },
                        includes: [],
                        size: 50,
                        from: 0,
                        sort: {
                            createdOn: 'desc',
                        },
                        source: false,
                    };
                    return await this.searchBlackboards(searchParams);
                })
            );

            this.subs.push(
                ...results.map((result, index) => {
                    return result.values.subscribe((messages) => {
                        messageTypes[Object.keys(messageTypes)[index]].next(messages);
                    });
                })
            );
        });
    }

    async loadMoreMessages(messageRecipient: SearchEntity, createdOn: any): Promise<number> {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/blackboard`);
        const q = query(
            collectionRef,
            where('creator.id', '==', this.userService.user.id),
            where('type', '==', messageRecipient),
            orderBy('createdOn', 'desc'),
            startAfter(createdOn),
            limit(this.MESSAGE_LOAD_STEP)
        );

        return getDocs(q).then((querySnap: QuerySnapshot<any>) => {
            const newMessages = querySnap.docs.map((doc) => doc.data() as BulletinBoardEntry);
            const normalizedMessages = this.normalizeFirebaseMessages(newMessages);

            switch (messageRecipient) {
                case SearchEntity.BROADCAST:
                    this.broadcastMessages$.next(this.broadcastMessages$.getValue().concat(normalizedMessages));
                    break;
                case SearchEntity.PROPERTY:
                    this.propertyMessages$.next(this.propertyMessages$.getValue().concat(normalizedMessages));
                    break;
                case SearchEntity.FLAT:
                    this.flatMessages$.next(this.flatMessages$.getValue().concat(normalizedMessages));
                    break;
                case SearchEntity.USER:
                    this.userMessages$.next(this.userMessages$.getValue().concat(normalizedMessages));
                    break;
            }
            return newMessages.length;
        });
    }

    getMessagesForProperties(propertyIds: string[]): Promise<BulletinBoardEntry[]> {
        return this.getMessagesForRecipient(propertyIds, SearchEntity.PROPERTY, 'propertyId');
    }

    getMessagesForFlats(flatIds: string[]): Promise<BulletinBoardEntry[]> {
        return this.getMessagesForRecipient(flatIds, SearchEntity.FLAT, 'flatId');
    }

    getMessagesForUsers(userIds: any[]): Promise<BulletinBoardEntry[]> {
        return this.getMessagesForRecipient(userIds, SearchEntity.USER, 'uid');
    }

    private async getMessagesForRecipient(objectIds: string[], recipient: SearchEntity, idPropertyName: string) {
        const promises = objectIds.map((id) => {
            const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/blackboard`);
            const q = query(
                collectionRef,
                where('creator.id', '==', this.userService.user.id),
                where('type', '==', recipient),
                where(idPropertyName, '==', id),
                orderBy('createdOn', 'desc')
            );
            return getDocs(q).then((querySnap: QuerySnapshot<any>) => {
                const docs = querySnap.docs.map((doc) => doc.data());
                return this.normalizeFirebaseMessages(docs);
            });
        });

        return Promise.all(promises).then((messagesArrays) => {
            return [].concat(...messagesArrays);
        });
    }

    async getAllMessagesForEntity(
        recipientId: string,
        recipientType: SearchEntity,
        afterDate: Date = new Date()
    ): Promise<any> {
        let recipientField: string;

        switch (recipientType) {
            case SearchEntity.PROPERTY:
                recipientField = 'propertyIds';
                break;
            case SearchEntity.FLAT:
                recipientField = 'flatIds';
                break;
            default:
                recipientField = 'uids';
                break;
        }

        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/blackboard`);
        const q = query(
            collectionRef,
            where('type', '==', recipientType),
            where(recipientField, 'array-contains', recipientId),
            orderBy('createdOn', 'desc'),
            startAfter(afterDate),
            limit(this.MESSAGE_LOAD_STEP)
        );

        const querySnap = await getDocs(q);
        const messages = querySnap.docs.map((doc) => doc.data() as BulletinBoardEntry);
        return this.normalizeFirebaseMessages(messages);
    }

    private normalizeFirebaseMessages(messages): BulletinBoardEntry[] {
        return messages.map((message) => {
            convertFirestoreDate(message);
            return message;
        });
    }

    async getMessage(id: string): Promise<BulletinBoardEntry> {
        const docRef = doc(this.firestore, `ns/${this.userService.getNamespace()}/blackboard/${id}`);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        convertFirestoreDate(data);
        return data as BulletinBoardEntry;
    }

    async deleteMessage(id: string) {
        return await this.apiService.delete(`blackboard/${id}`);
    }

    public async createAndEditBulletinboardEntry(
        recipientIds: string[],
        title: string,
        description: string,
        validFrom: Date,
        validTo: Date,
        documents: any,
        mode: SearchEntity,
        active: boolean,
        userType: string,
        allowComments: boolean,
        ticketId: string,
        ticketLinkVisible: string,
        providerIds: string[],
        blackboardId?,
        notify?
    ): Promise<any> {
        if (
            this.userService.user &&
            (recipientIds?.length || mode === SearchEntity.BROADCAST) &&
            title &&
            description &&
            validTo
        ) {
            if (!validFrom) {
                validFrom = new Date();
            }

            const message: any = {
                title: {
                    original: {
                        value: title,
                        language: this.userService.userLanguage$.value,
                    },
                },
                text: {
                    original: {
                        value: description,
                        language: this.userService.userLanguage$.value,
                    },
                },
                validFrom,
                validTo,
                userType,
                showOnBlackboard: active,
                ticketId,
                ticketLinkVisible,
                allowComments,
                providerIds,
            };

            if (notify) {
                message.notify = 'all';
            }

            switch (mode) {
                case SearchEntity.PROPERTY:
                    message.propertyIds = recipientIds;
                    message.type = 'PROPERTY';
                    break;
                case SearchEntity.FLAT:
                    message.flatIds = recipientIds;
                    message.type = 'FLAT';
                    break;
                case SearchEntity.USER:
                    message.uids = recipientIds;
                    message.type = 'PERSONAL';
                    break;
                case SearchEntity.BROADCAST:
                    message.type = 'BROADCAST';
                    break;
            }

            let id = blackboardId;

            if (blackboardId) {
                // IMPORTANT: Documents uploads are not handled in update case
                const updatedBulletinEntry = await this.updateBulletinEntry(blackboardId, message);

                await this.documentUtilService.deleteFilesFromObject(
                    blackboardId,
                    'blackboard',
                    updatedBulletinEntry.documents,
                    documents
                );
            } else {
                documents = await this.documentUtilService.prepareDocuments(documents);
                const result = await this.createBulletinEntry(
                    {
                        ...message,
                        creator: {
                            id: this.userService.user.id,
                            name: this.userService.getUserFullName(),
                            profilePicture: this.userService.user.profilePicture || null,
                            type: 'manager',
                        },
                    },
                    [...documents.imgs, ...documents.pdfs]
                );

                id = result.id;
            }

            return { ...message, id };
        } else {
            return Promise.reject({
                message: 'Missing data or no user present',
            });
        }
    }

    terminate() {
        this.propertyMessages$.next(null);
        this.flatMessages$.next(null);
        this.userMessages$.next(null);

        this.globalBlackboardMessagesListener?.unsubscribe();

        if (Array.isArray(this.subs)) {
            this.subs.forEach((sub) => {
                sub?.unsubscribe();
            });
            this.subs = [];
        }
    }

    private async createBulletinEntry(message: any, files: any): Promise<any> {
        const formData = new FormData();
        formData.append('model', JSON.stringify(message));

        if (files.length) {
            for (const file of files) {
                const name = file.editedName && file.editedName.length ? file.editedName : file.name;
                formData.append(name, file.file, name);
            }
        }

        return await this.apiService.post('blackboard', formData);
    }

    private async updateBulletinEntry(blackboardId: string, message: any): Promise<any> {
        return await this.apiService.put('blackboard', {
            ...message,
            id: blackboardId,
        });
    }

    public async getBroadcastRecipientCounts(): Promise<RecipientCounts> {
        return (await this.apiService.get('blackboard/broadcast/recipientCounts')) as RecipientCounts;
    }

    public async countBlackboardComments(blackboardId: string) {
        return this.elasticService.searchBlackboardComments({
            size: 0,
            filter: {
                'blackboardId.keyword': blackboardId,
            },
        });
    }

    public async hasUnreadBlackboardComments(blackboardId: string) {
        const result = await this.elasticService.searchBlackboardComments({
            size: 0,
            filter: {
                'blackboardId.keyword': blackboardId,
            },
            excludes: {
                'readBy.keyword': this.userService.user.id,
            },
        });

        return !!result.total;
    }

    observeBlackboardComments(blackboardId: string, withTexts: boolean = false): Observable<any[]> {
        const commentsCollectionRef = collection(
            this.firestore,
            `ns/${this.userService.getNamespace()}/blackboardComments`
        );
        const commentsQuery = query(commentsCollectionRef, where('blackboardId', '==', blackboardId));

        return collectionData(commentsQuery, { idField: 'id' }).pipe(
            switchMap((comments: any[]) => {
                if (!comments?.length) {
                    return of([]);
                }
                convertFirestoreDate(comments);
                comments.sort((a, b) => {
                    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                });

                if (withTexts) {
                    return combineLatest(
                        comments.map((comment) =>
                            this.observeCommentTexts(blackboardId, null, comment.id).pipe(
                                map((texts: any[]) => {
                                    texts.forEach((text) => {
                                        this.applyTextToComment(comment, text);
                                    });
                                    return comment;
                                })
                            )
                        )
                    );
                } else {
                    return of(comments);
                }
            })
        );
    }

    public getBlackboardCommentTemplate(blackboardId: string, text: string) {
        return {
            blackboardId,
            text,
            createdOn: new Date(),
            creator: {
                type: 'manager',
                id: this.userService.user.id,
                name: this.userService.getUserFullName(this.userService.user),
            },
        };
    }

    public async createBlackboardComment(dto: any, documents: any) {
        const comment = await this.apiService.post(`blackboard/${dto.blackboardId}/comments`, dto);
        if (documents) {
            documents = await this.documentUtilService.prepareDocuments(documents);
            if (documents.imgs.length || documents.pdfs.length) {
                await this.documentsRequests.upload(`blackboard/${comment.blackboardId}/comments`, comment.id, [
                    ...documents.imgs,
                    ...documents.pdfs,
                ]);
            }
        }
    }

    public async updateBlackboardComment(dto: any, documents: any) {
        const comment = await this.apiService.put(`blackboard/${dto.blackboardId}/comments`, dto);
        if (documents) {
            documents = await this.documentUtilService.prepareDocuments(documents);
            if (documents.imgs.length || documents.pdfs.length) {
                await this.documentsRequests.upload(`blackboard/${comment.blackboardId}/comments`, comment.id, [
                    ...documents.imgs,
                    ...documents.pdfs,
                ]);
            }
        }
    }

    public async deleteBlackboardComment(blackboardId: string, commentId: string) {
        await this.apiService.delete(`blackboard/${blackboardId}/comments/${commentId}`);
    }

    subscribeBlackboardCommentsCount(blackboardId: string): Observable<any[]> {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/blackboardComments`);
        const q = query(
            collectionRef,
            where('blackboardId', '==', blackboardId),
            orderBy('createdOn', 'desc'),
            limit(10)
        );
        return collectionData(q);
    }

    observeCommentTexts(blackboardId: string, type: string, commentId: string): Observable<any[]> {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTexts`);
        const q = query(collectionRef, where('key', '==', `blackboard.${blackboardId}.comment.${commentId}`));
        return collectionData(q);
    }

    private applyTextToComment(comment: any, text: any) {
        if (!comment) {
            comment = {};
        }

        if (!comment.text || typeof comment.text !== 'object') {
            comment.text = {};
        }

        if (text.type === 'original') {
            comment.text.original = {
                language: text.lang,
                value: text.value,
            };
            comment.text[text.lang] = text.value;
        } else {
            if (!comment.text[text.lang]) {
                comment.text[text.lang] = text.value;
            }
        }

        return comment;
    }
}
