import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'erpFields',
})
export class ErpFieldsPipe implements PipeTransform {
    transform(field: string[]) {
        return field?.length ? field.join(', ').trim() : '-';
    }
}
