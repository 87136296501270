import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Language } from '../../models/language';
import { BaseDataService } from '../../util/base-data-service';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements BaseDataService {
    private languages: Language[] = [];

    constructor(private firestore: Firestore) {}

    searchLanguage(searchTerm: string = ''): Language[] {
        if (!searchTerm.length) {
            return this.languages;
        }
        const cleanedSearchTerm = searchTerm.trim().toLowerCase();
        return this.languages.filter((lang) => {
            return (
                lang.iso639_1.toLowerCase().indexOf(cleanedSearchTerm) > -1 ||
                lang.iso639_2.toLowerCase().indexOf(cleanedSearchTerm) > -1 ||
                lang.name.toLowerCase().indexOf(cleanedSearchTerm) > -1 ||
                lang.nativeName.toLowerCase().indexOf(cleanedSearchTerm) > -1
            );
        });
    }

    getFullLanguageName(langShort: string): string {
        const lang = this.languages.find((langObj) => {
            return langObj.iso639_1 === langShort;
        });
        return lang ? lang.nativeName : null;
    }

    async initialize(): Promise<void> {
        const path = `languages/${environment.firebase.languageDocId}`;
        const lang = await getDoc(doc(this.firestore, path));
        this.languages = lang.get('all').sort((a, b) => {
            if (a.iso639_1 > b.iso639_1) {
                return 1;
            }
            return -1;
        });
    }

    terminate() {
        this.languages = [];
    }
}
