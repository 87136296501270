import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'customTranslation',
    pure: false,
})
export class CustomTranslationPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: any, ...args: any[]): string {
        const placeholder: Record<string, string> | boolean = args.length ? args[0] : false;
        let finalValue: string = '';

        if (value && typeof value === 'object') {
            const lang: string = this.translate.currentLang;
            const originalLang: string | null = value.original.language || null;
            if (lang === originalLang) {
                finalValue = value.original.value;
            } else if (value[lang]) {
                finalValue = value[lang];
            } else {
                finalValue = value.original.value;
            }
        } else if (value && typeof value === 'string') {
            finalValue = this.translate.instant(value);
        }

        if (finalValue && placeholder) {
            finalValue = this.fillPlaceholders(finalValue, placeholder as Record<string, string>);
        }

        return finalValue;
    }

    fillPlaceholders(finalValue: string, placeholder: Record<string, string>): string {
        for (const placeholderKey of Object.getOwnPropertyNames(placeholder)) {
            while (finalValue.includes(`$[${placeholderKey}]`)) {
                finalValue = finalValue.replace(
                    `$[${placeholderKey}]`,
                    placeholder[placeholderKey] ? placeholder[placeholderKey] : ''
                );
            }
        }

        return finalValue;
    }
}
