import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListWithChipComponentModule } from '../components/organisms/list-with-chip/list-with-chip.component.module';
import { CraftsmanSelectionComponentModule } from './craftsman-selection/craftsman-selection.component.module';
import { OfferAppointmentSelectionComponentModule } from './offer-appointment-selection/offer-appointment-selection.component.module';
import { ChangeManagerComponentModule } from './change-manager/change-manager.component.module';
import { LegalDocumentsAcceptModalComponentModule } from './legal-documents/legal-documents-accept-modal.component.module';

@NgModule({
    declarations: [],
    exports: [],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ListWithChipComponentModule,
        CraftsmanSelectionComponentModule,
        OfferAppointmentSelectionComponentModule,
        ChangeManagerComponentModule,
        LegalDocumentsAcceptModalComponentModule,
    ],
})
export class ModalsModule {}
