import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { ChangeManagerComponent } from './change-manager.component';
import { TopbarSearchComponentModule } from '../../components/molecules/topbar-search/topbar-search.component.module';

@NgModule({
    declarations: [ChangeManagerComponent],
    exports: [ChangeManagerComponent],
    imports: [ComponentsSharedModule, TopbarSearchComponentModule],
})
export class ChangeManagerComponentModule {}
