import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Browser, OpenOptions } from '@capacitor/browser';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pdf-component',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfComponent {
    @Input() document: any;
    loading: any;

    constructor(
        public modalController: ModalController,
        private popupService: PopupService,
        private translate: TranslateService
    ) {}

    async download() {
        const openOption: OpenOptions = {
            url: this.document.file,
            windowName: '_system',
        };
        await Browser.open(openOption);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async loadingFailed() {
        await this.popupService.showAlert('', this.translate.instant('documents.download-failed'), [
            {
                text: this.translate.instant('button.close'),
                handler: () => {
                    this.modalController.dismiss();
                },
            },
        ]);
    }
}
