import { Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../services/popup/popup.service';
import { Browser, OpenOptions } from '@capacitor/browser';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/swiper-element';
import Swiper from 'swiper';

@Component({
    selector: 'app-photo-viewer',
    templateUrl: './photo-viewer.component.html',
    styleUrls: ['./photo-viewer.component.scss'],
})
export class PhotoViewerComponent implements OnInit {
    @ViewChild('swiper', { static: false }) swiperRef: ElementRef;
    slideOpts: SwiperOptions = {
        initialSlide: 0,
        centeredSlides: true,
        spaceBetween: 10,
        zoom: true,
    };
    @Input() images: any[] = [];
    @Input() index: number = 0;
    @Input() imageDeleteAllowed: boolean = true;

    leftIcon: string;

    constructor(
        public modalController: ModalController,
        private popupService: PopupService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.slideOpts.initialSlide = this.index;
        this.leftIcon = this.imageDeleteAllowed ? 'trash' : 'save-outline';
    }

    async leftFunction() {
        if (this.imageDeleteAllowed) {
            await this.deleteImage();
        } else {
            await this.download();
        }
    }

    async download() {
        const openOption: OpenOptions = {
            url: this.images[this.index]?.file,
            windowName: '_system',
        };
        await Browser.open(openOption);
    }

    getCurrentSlideIndex(): number {
        const swiperEl = this.swiperRef.nativeElement as SwiperContainer & { swiper: Swiper };
        return swiperEl.swiper.activeIndex;
    }

    async deleteImage() {
        const result = await this.popupService.showConfirm(
            this.translate.instant('ticket-pictures.confirm-delete.title'),
            this.translate.instant('ticket-pictures.confirm-delete.message')
        );

        if (result) {
            await this.modalController.dismiss(this.getCurrentSlideIndex());
        }
    }
}
