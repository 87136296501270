import { Injectable } from '@angular/core';
import { DocumentsRequests } from './../../util/documents-requests';
import { Flat } from '../../models/flat';
import { UserService } from '../user/user.service';
import {
    collection,
    doc,
    docData,
    Firestore,
    getDoc,
    getDocs,
    query,
    QuerySnapshot,
    where,
} from '@angular/fire/firestore';
import { User } from '../../models/user';
import { ApiService } from './../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class FlatService {
    private flats = {};
    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private apiService: ApiService,
        private documentsRequests: DocumentsRequests
    ) {}

    async getFlatsByProperty(propertyId: string): Promise<Flat[]> {
        const flatsCollection = collection(this.firestore, `ns/${this.userService.getNamespace()}/flats`);
        const q = query(flatsCollection, where('propertyId', '==', propertyId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => doc.data() as Flat);
    }

    async getFlat(flatId: string) {
        if (this.flats[flatId]) {
            return await this.flats[flatId];
        }
        const flatDoc = doc(this.firestore, `ns/${this.userService.getNamespace()}/flats/${flatId}`);
        const flatSnapshot = await getDoc(flatDoc);
        const flat = flatSnapshot.data() as Flat;
        this.flats[flatId] = flat;
        return flat;
    }

    getFlatObservable(id: string) {
        const flatDoc = doc(this.firestore, `ns/${this.userService.getNamespace()}/flats/${id}`);
        return docData(flatDoc);
    }

    async getFlatsByIds(ids: string[]) {
        return (await this.apiService.get(`flats?id=${ids.join(',')}`)) as any[];
    }

    async getUsersForFlat(flat: Flat): Promise<User[]> {
        const usersCollection = collection(this.firestore, 'users');
        const q = query(
            usersCollection,
            where('ns', '==', this.userService.getNamespace()),
            where('activeFlats', 'array-contains', flat.id)
        );
        return getDocs(q).then((usersSnap: QuerySnapshot<User>) => {
            return usersSnap.docs.map((us) => us.data() as User);
        });
    }

    async uploadFiles(documents: { pdfs: any[]; imgs: any[] }, flatId: string, path = '') {
        if (!documents || !documents.imgs || !documents.pdfs) {
            return null;
        } else if (documents.imgs.length || documents.pdfs.length) {
            return this.documentsRequests.upload('flats', flatId, [...documents.imgs, ...documents.pdfs], path);
        } else {
            return null;
        }
    }

    async deleteFlatFile(flatId: string, index: number, fileType: 'imgs' | 'pdfs') {
        return this.documentsRequests.delete('flats', flatId, fileType, index);
    }
}
