import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/services/app-injector/app-injector.service';
import { register as registerSwiperElements } from 'swiper/element/bundle';
registerSwiperElements();

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((module) => {
        AppInjector.setInjector(module.injector);
    });
