import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { AutofocusModule } from '../../../directives/autofocus/autofocus.module';
import { LegalDocumentsAcceptComponent } from './legal-documents-accept.component';

@NgModule({
    imports: [ComponentsSharedModule, AutofocusModule],
    declarations: [LegalDocumentsAcceptComponent],
    exports: [LegalDocumentsAcceptComponent],
})
export class LegalDocumentsAcceptComponentModule {}
