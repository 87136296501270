<ion-tabs>
    <ion-tab-bar slot="bottom">
        <ion-tab-button
            *ngIf="
                (userService?.moduleSubscription?.value || []).includes('tickets') ||
                (userService?.moduleSubscription?.value || []).includes('handover')
            "
            tab="tickets">
            <ion-icon name="flash"></ion-icon>
            <ion-label>{{ 'menu.requests' | translate }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
            *ngIf="(userService?.moduleSubscription?.value || []).includes('communication')"
            tab="bulletinboard">
            <ion-icon name="clipboard"></ion-icon>
            <ion-label>{{ 'menu.blackboard' | translate }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="search">
            <ion-icon name="search"></ion-icon>
            <ion-label>{{ 'menu.search' | translate }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="news">
            <ion-icon name="notifications"></ion-icon>
            <ion-label>{{ 'menu.news' | translate }}</ion-label>
            <ion-badge *ngIf="notifications > 0">
                {{ notifications > 9 ? '9+' : notifications }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="profile">
            <ion-icon name="person"></ion-icon>
            <ion-label>{{ 'menu.profile' | translate }}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>
