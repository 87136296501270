import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, scheduled, asapScheduler } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { OriginService } from '../origin/origin.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private originService: OriginService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return scheduled(this.userService.getToken(), asapScheduler).pipe(
            switchMap((token) => {
                if (this.originService.origin) {
                    request = request.clone({
                        setHeaders: {
                            'X-App-Origin': this.originService.origin,
                        },
                    });
                }
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }
                return next.handle(request);
            })
        );
    }
}
