import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, take, timeout } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { Manager } from '../../models/manager';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Auth, browserLocalPersistence, signInWithCustomToken, UserCredential } from '@angular/fire/auth';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import firebase from 'firebase/compat';
import IdTokenResult = firebase.auth.IdTokenResult;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user: Observable<any>;
    maintenance$: Subject<boolean> = new BehaviorSubject(false);
    nsMaintenance$: Subject<boolean> = new BehaviorSubject(false);
    namespaceTokens$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    currentNamespace: string = null;
    nsSubscription: Subscription;

    constructor(
        private auth: Auth,
        private firestore: Firestore,
        private userService: UserService,
        private http: HttpClient
    ) {}

    public async initialize(): Promise<void> {
        const maintenanceDocRef = doc(this.firestore, 'public/maintenance');
        docData(maintenanceDocRef).subscribe((maintenance: { active: boolean }) => {
            this.maintenance$.next(Boolean(maintenance && maintenance.active));
        });
        this.userService.user$.subscribe((manager: Manager) => {
            this.registerNamespaceMaintenance(manager);
        });
    }
    private registerNamespaceMaintenance(manager: Manager) {
        if (this.nsSubscription) {
            this.nsSubscription.unsubscribe();
        }

        if (manager) {
            const nsDocRef = doc(this.firestore, `ns/${manager.ns}`);
            this.nsSubscription = docData(nsDocRef).subscribe((namespace: any) => {
                this.nsMaintenance$.next(Boolean(namespace && namespace.maintenance));
            });
        }
    }

    async getCurrentUser(): Promise<Manager> {
        try {
            const user: Manager = await firstValueFrom(
                this.userService.user$.pipe(
                    filter((user: Manager): boolean => user !== undefined),
                    take(1)
                )
            );
            return user;
        } catch (error) {
            return null;
        }
    }

    public async login(email: string, password: string, oldToken: string = ''): Promise<any[]> {
        await this.auth.setPersistence(browserLocalPersistence);
        const authResponse: any = await lastValueFrom(
            this.http.post(`${environment.apiBase}managers/authenticate`, {
                email,
                password,
                token: oldToken,
            })
        );

        this.namespaceTokens$.next(authResponse.tokens);
        const selected = authResponse.tokens.find((token) => token.selected);

        if (!this.hasValidMultiNamespaceTokens(authResponse.tokens) || selected) {
            await this.loginWithCustomToken(
                selected ? selected.token : authResponse.tokens.find((namespaceToken) => namespaceToken?.token)?.token
            );
        }

        return this.namespaceTokens$.value;
    }

    hasValidMultiNamespaceTokens(namespaceTokens = []): boolean {
        return namespaceTokens.filter((namespaceToken) => namespaceToken.token).length > 1;
    }

    public async loginWithCustomToken(token: string): Promise<UserCredential> {
        const userCredential: UserCredential = await signInWithCustomToken(this.auth, token);
        await this.handleLoginResult(userCredential);
        return userCredential;
    }

    private async handleLoginResult(firebaseUserObject: UserCredential): Promise<UserCredential> {
        return new Promise(async (resolve, reject) => {
            try {
                const token: IdTokenResult = await firebaseUserObject.user.getIdTokenResult();
                this.userService.moduleSubscription.next(token?.claims?.modules || []);

                if (token.claims.pm) {
                    this.currentNamespace = token.claims.ns;

                    const fsUser = await firstValueFrom(
                        this.userService.user$.pipe(
                            filter((manager: Manager) => !!manager),
                            take(1),
                            timeout(2000),
                            catchError(() => of(null))
                        )
                    );

                    if (fsUser) {
                        resolve(firebaseUserObject);
                    } else {
                        reject({ message: 'Error, please try again' });
                    }
                } else {
                    reject({ message: 'Not a property manager' });
                }
            } catch (err) {
                reject();
            }
        });
    }

    async logout(): Promise<void> {
        await this.auth.signOut();
        location.reload();
    }

    sendPasswordResetMail(email: string): Promise<object> {
        return firstValueFrom(this.http.post(`${environment.apiBase}managers/resetPassword/${email}`, {}));
    }
}
