import { Injectable } from '@angular/core';
import { FileDocument } from '../../models/file-document';

@Injectable({
    providedIn: 'root',
})
export class DesktopFilePickerService {
    constructor() {}

    pickOne(fileTypes: string): Promise<FileDocument> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', fileTypes);
            input.setAttribute('multiple', 'false');
            input.addEventListener('change', async (event: any) => {
                if (event.target.files && event.target.files.length) {
                    resolve(this.readFile(event.target.files[0]));
                } else {
                    reject();
                }
                input.remove();
            });
            input.style.display = 'none';
            document.querySelector('body').appendChild(input);
            input.click();
        });
    }

    async pickMultiple(fileTypes: string): Promise<FileDocument[]> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', fileTypes);
            input.setAttribute('multiple', 'true');
            input.addEventListener('change', async (event: any) => {
                if (event.target.files && event.target.files.length) {
                    resolve(Object.keys(event.target.files).map((key) => this.readFile(event.target.files[key])));
                } else {
                    reject();
                }
                input.remove();
            });
            input.style.display = 'none';
            document.querySelector('body').appendChild(input);
            input.click();
        });
    }

    public readFile(file: File): FileDocument {
        return {
            name: file.name,
            file,
            mimetype: file.type,
        };
    }
}
