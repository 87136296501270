import { Injectable } from '@angular/core';
import { ElasticService } from '../elastic/elastic.service';
import { UserService } from '../user/user.service';
import { BlackboardService } from '../blackboard/blackboard.service';
import { NamespaceService } from '../namespace/namespace.service';
import { TicketsService } from '../tickets/tickets.service';
import { TextService } from '../text/text.service';
import { TicketTreeDataService } from '../ticket-tree-data/ticket-tree-data.service';
import { ThemeService } from '../theme/theme.service';
import { BehaviorSubject } from 'rxjs';
import { Timeout } from '../../util/timeout';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../popup/popup.service';
import { take } from 'rxjs/operators';
import { PushNotificationService } from '../push-notification/push-notification.service';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    appDataInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private elasticService: ElasticService,
        private userService: UserService,
        private blackboardService: BlackboardService,
        private namespaceService: NamespaceService,
        private ticketService: TicketsService,
        private textService: TextService,
        private ticketTreeDataService: TicketTreeDataService,
        private pushNotificationService: PushNotificationService,
        private themeService: ThemeService,
        private navController: NavController,
        private translate: TranslateService,
        private popupService: PopupService
    ) {}

    async initializeAppData() {
        await this.textService.initialize();
        await Promise.all([
            this.elasticService.initialize(),
            this.ticketService.initialize(),
            this.blackboardService.initialize(),
            this.ticketTreeDataService.initialize(),
        ]);

        this.appDataInitialized$.next(true);
    }

    async initializeEssentialAppData() {
        await this.userService.getUser();

        return Promise.all([
            this.userService.getTheme().then((theme) => {
                this.themeService.setTheme(theme);
            }),
            this.namespaceService.initialize(),
        ]);
    }

    async setupAfterLogin() {
        await this.navController.navigateRoot('start');
        await Promise.all([this.initializeAppData(), Timeout(2000)]);
        const user = await this.userService.getUser();
        this.translate.resetLang(this.translate.currentLang);

        if (!user.language) {
            try {
                await this.userService.setLanguage(this.translate.currentLang);
            } catch (err) {
                console.error(`Set Language ${this.translate.currentLang} Failed`, err);
                await this.popupService.showToast(this.translate.instant('login.set-language-error'));
            }

            this.translate.resetLang(this.translate.currentLang);
            await this.translate.reloadLang(this.translate.currentLang).pipe(take(1)).toPromise();
        } else {
            if (user.language === this.translate.currentLang) {
                this.translate.use(null);
            }

            this.translate.resetLang(user.language);
            await Timeout(200);
            this.translate.use(user.language);

            try {
                await this.userService.setLanguage(user.language, true);
            } catch (err) {
                console.error(`Set Language ${this.translate.currentLang} Failed`, err);
                await this.popupService.showToast(this.translate.instant('login.set-language-error'));
            }
        }

        await this.navController.navigateRoot('main');
        await Timeout(500);
    }

    clearAppData() {
        this.ticketService.terminate();
        this.blackboardService.terminate();
        this.namespaceService.terminate();
        this.textService.terminate();
        this.pushNotificationService.terminate();
        this.ticketTreeDataService.terminate();
        this.appDataInitialized$.next(false);
    }
}
