import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-force-update-modal',
    templateUrl: './force-update-modal.component.html',
    styleUrls: ['./force-update-modal.component.scss'],
})
export class ForceUpdateModalComponent {
    storeUrl: any = '';
    storeImg: string = '';
    message: string = '';

    constructor(private sanitizer: DomSanitizer) {
        if (Capacitor.getPlatform() === 'ios') {
            this.storeUrl = this.sanitizer.bypassSecurityTrustUrl(environment.store_link.ios);
            this.storeImg =
                'https://storage.googleapis.com/app_store_icons/Download-on-the-App-Store/itunes-app-store-logo-1.png';
        } else if (Capacitor.getPlatform() === 'android') {
            this.storeImg = `https://play.google.com/intl/en/badges/images/generic/en_badge_web_generic.png`;
            this.storeUrl = environment.store_link.android;
        }
    }
}
