<ion-toolbar>
    <ion-buttons slot="start" *ngIf="left || leftText">
        <ion-button (click)="leftButtonClicked($event)" *ngIf="left">
            <ion-icon slot="icon-only" [name]="left"></ion-icon>
        </ion-button>
        <ion-back-button #backButton *ngIf="left && leftBack" defaultHref="main"></ion-back-button>
        <ion-button fill="clear" (click)="leftClicked.emit($event)" *ngIf="leftText">
            {{ leftText }}
        </ion-button>
    </ion-buttons>
    <ion-title>
        {{ title | translate }}
        <ion-icon
            *ngIf="showNotificationBlocker"
            class="no-notifications"
            name="notifications-off-outline"
            color="warning"></ion-icon>
    </ion-title>
    <ion-buttons slot="end">
        <ion-button (click)="rightClicked.emit($event)" *ngIf="right">
            <ion-icon slot="icon-only" [name]="right"></ion-icon>
        </ion-button>
        <ion-button fill="clear" (click)="rightClicked.emit($event)" *ngIf="rightText">
            {{ rightText }}
        </ion-button>
    </ion-buttons>
</ion-toolbar>
