import { NgModule } from '@angular/core';
import { LegalDocumentsAcceptComponentModule } from './../../components/molecules/legal-documents-accept/legal-documents-accept.component.module';
import { LegalDocumentsAcceptModalComponent } from './legal-documents-accept-modal.component';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';

@NgModule({
    declarations: [LegalDocumentsAcceptModalComponent],
    exports: [LegalDocumentsAcceptModalComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule, LegalDocumentsAcceptComponentModule],
})
export class LegalDocumentsAcceptModalComponentModule {}
