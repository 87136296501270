import { LegalDocumentsService } from '../../../services/legal-documents/legal-documents.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BrowserService } from '../../../services/browser/browser.service';
import * as clonedeep from 'lodash.clonedeep';
import { NamespaceService } from 'src/app/services/namespace/namespace.service';
import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-legal-documents-accept',
    templateUrl: './legal-documents-accept.component.html',
    styleUrls: ['./legal-documents-accept.component.scss'],
})
export class LegalDocumentsAcceptComponent implements OnInit, OnDestroy {
    @Input() excludedDocuments = [];
    @Input() options = {
        modalStyle: false,
    };

    @Output() change: EventEmitter<any> = new EventEmitter();

    legalCheckboxes: { termsOfUse: boolean; privacyPolicy: boolean } = {
        termsOfUse: false,
        privacyPolicy: false,
    };

    currentLegalDocuments = { privacyPolicy: null, termsOfUse: null };

    private subscriptions: Subscription[] = [];

    constructor(
        private legalDocumentsService: LegalDocumentsService,
        private browserService: BrowserService,
        private namespaceService: NamespaceService,
        private documentUtilService: DocumentUtilService
    ) {}

    /**
     * Initialize needed data for component
     * Get current legal documents from database
     * Compute legalCheckboxes
     */
    ngOnInit() {
        this.legalCheckboxes = this.removeExcludedDocumentsFromObject(this.legalCheckboxes);

        this.subscriptions.push(
            this.legalDocumentsService.getLatestLegalDocumentsObservable().subscribe(async (legalDocuments) => {
                const domain = await this.namespaceService.getDomain();
                this.currentLegalDocuments = this.removeExcludedDocumentsFromObject(legalDocuments[domain]);
            })
        );
    }

    /**
     * On change of checkboxes or slide-toggles
     * Will emit changes to parent component
     *
     * @returns none
     */
    onChange() {
        if (this.validateCheckboxes()) {
            return this.change.emit({
                legalDocuments: this.currentLegalDocuments,
                allAccepted: true,
            });
        }

        return this.change.emit({
            legalDocuments: this.currentLegalDocuments,
            allAccepted: false,
        });
    }

    /**
     * Open legal document (PDF) in InAppBrowser
     *
     * @param url url to PDF legal document as string
     */
    async openLegalDocumentByURL(url: string) {
        const document = {
            file: url,
            name: 'Legaldocument.pdf',
            mimetype: 'application/pdf',
        };

        if (Capacitor.isNativePlatform()) {
            await this.documentUtilService.openDocument(document);
        }
        this.browserService.open(url, '_blank');
    }

    /**
     * Validate if all checkboxes are set
     *
     * @returns all checkboxes set (boolean)
     */
    private validateCheckboxes() {
        return Object.values(this.legalCheckboxes).every(Boolean);
    }

    /**
     * Helper function to remove certain fields from object
     *
     * @param object object where fields should be removed
     * @returns object with removed fields
     */
    private removeExcludedDocumentsFromObject(object: any) {
        const clonedObject = clonedeep(object);

        if (this.excludedDocuments?.length) {
            for (const exludedDocument of this.excludedDocuments) {
                delete clonedObject[exludedDocument];
            }
        }

        return clonedObject;
    }

    /**
     * Clear all subscriptions before destroying component to prevent unused open subscriptions
     *
     */
    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }
}
