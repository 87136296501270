<div class="legal">
    <ion-list>
        <ion-item *ngIf="currentLegalDocuments?.termsOfUse">
            <ion-label class="ion-text-wrap">
                {{ 'legal-documents.accept.terms-of-use' | translate }}

                <br />

                <a (click)="openLegalDocumentByURL(currentLegalDocuments.termsOfUse.url)">
                    {{ 'legal-documents.name.terms-of-use' | translate }}
                    v{{ currentLegalDocuments.termsOfUse.version }}
                </a>
            </ion-label>

            <ion-toggle color="primary" [(ngModel)]="legalCheckboxes.termsOfUse" (ionChange)="onChange()"></ion-toggle>
        </ion-item>

        <ion-item *ngIf="currentLegalDocuments?.privacyPolicy">
            <ion-label class="ion-text-wrap">
                {{ 'legal-documents.accept.privacy-policy' | translate }}

                <br />

                <a (click)="openLegalDocumentByURL(currentLegalDocuments.privacyPolicy.url)">
                    {{ 'legal-documents.name.privacy-policy' | translate }} v{{
                        currentLegalDocuments.privacyPolicy.version
                    }}
                </a>
            </ion-label>

            <ion-toggle
                color="primary"
                [(ngModel)]="legalCheckboxes.privacyPolicy"
                (ionChange)="onChange($event)"></ion-toggle>
        </ion-item>
    </ion-list>
</div>
