import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as Color from 'color';
import hexRgb from 'hex-rgb';
import { Theme } from '../../models/theme';
import { OriginService } from '../origin/origin.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ColorBrightnessEnum } from './color-brightness.enum';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private readonly shadeRatio = 0.1;
    private readonly tintRatio = 0.1;
    brightness: ColorBrightnessEnum;
    private readonly defaultTheme: Theme = {
        primary: '#c8af88',
        secondary: '#223342',
        tertiary: '#ff7f7f',
        success: '#10dc60',
        warning: '#ffce00',
        danger: '#ff7f7f',
        dark: '#223342',
        medium: '#c9c9c9',
        light: '#f7f7f7',
        __badge: '#ff7f7f',
        __menuBg: '#ffffff',
        __menuColor: '#555555',
        __splashBg: '#223342',
        __splashSpinner: '#ffffff',
    };
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private originService: OriginService
    ) {}

    async setTheme(theme?: Theme) {
        if (this.originService.origin === 'ci-platform.app') {
            this.defaultTheme.primary = '#ff4d11';
            this.defaultTheme.__splashBg = '#ffffff';
        } else if (this.originService.origin === 'linkathome.app') {
            this.defaultTheme.primary = '#ef713f';
            this.defaultTheme.__splashBg = '#ffffff';
        }
        theme = Object.assign(Object.assign({}, this.defaultTheme), theme);
        const cssText = this.generateCSSText(theme);
        this.setGlobalCSS(cssText);
        if (Capacitor.isNativePlatform()) {
            // Ensures code runs only on native platforms
            try {
                switch (this.brightness) {
                    case ColorBrightnessEnum.DARK:
                    case ColorBrightnessEnum.VERYDARK:
                        await StatusBar.setStyle({ style: Style.Dark });
                        await StatusBar.setBackgroundColor({ color: theme.__menuBg });
                        break;
                    case ColorBrightnessEnum.LIGHT:
                    case ColorBrightnessEnum.VERYLIGHT:
                        await StatusBar.setStyle({ style: Style.Light });
                        await StatusBar.setBackgroundColor({ color: theme.__menuBg });
                        break;
                }
            } catch (error) {
                console.error('StatusBar Error:', error);
            }
        }
    }

    setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

    generateCSSText(theme: Theme) {
        /* tslint:disable */
        this.brightness = this.getColorBrightness(theme.__menuBg);
        // prettier-ignore
        return `
        ${this.colorToThemeDefinition('primary', theme.primary, hexRgb(theme.primary))}
        ${this.colorToThemeDefinition('secondary', theme.secondary, hexRgb(theme.secondary))} 
        ${this.colorToThemeDefinition('tertiary', theme.tertiary, hexRgb(theme.tertiary))} 
        ${this.colorToThemeDefinition('success', theme.success, hexRgb(theme.success))} 
        ${this.colorToThemeDefinition('warning', theme.warning, hexRgb(theme.warning))} 
        ${this.colorToThemeDefinition('danger', theme.danger, hexRgb(theme.danger))} 
        ${this.colorToThemeDefinition('dark', theme.dark, hexRgb(theme.dark))}
        ${this.colorToThemeDefinition('medium', theme.medium, hexRgb(theme.medium))}
        ${this.colorToThemeDefinition('light', theme.light, hexRgb(theme.light))}
        --badge-color: ${theme.__badge};
        --menu-background: ${theme.__menuBg};
        --menu-color: ${theme.__menuColor};
        --splash-bg: ${theme.__splashBg};
        --splash-spinner: ${theme.__splashSpinner};
        `;

        /* tslint:enable */
    }

    getColorBrightness(hex): ColorBrightnessEnum {
        hex = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
        const r = hex >> 16;
        const g = (hex >> 8) & 255;
        const b = hex & 255;

        const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
        if (hsp <= 63.75) {
            return ColorBrightnessEnum.VERYDARK;
        } else if (hsp <= 127.5) {
            return ColorBrightnessEnum.DARK;
        } else if (hsp <= 191.25) {
            return ColorBrightnessEnum.LIGHT;
        } else if (hsp <= 255) {
            return ColorBrightnessEnum.VERYLIGHT;
        } else {
            return ColorBrightnessEnum.LIGHT;
        }
    }

    setDefault() {
        const cssText = this.generateCSSText(this.defaultTheme);
        this.setGlobalCSS(cssText);
    }

    private colorToThemeDefinition(colorName, color, rgb) {
        return `              --ion-color-${colorName}: ${color};
            --ion-color-${colorName}-rgb: ${rgb.red}, ${rgb.green}, ${rgb.blue};
            --ion-color-${colorName}-contrast: #ffffff;
            --ion-color-${colorName}-contrast-rgb: 255,255,255;
            --ion-color-${colorName}-shade:  ${Color(color).darken(this.shadeRatio)};
            --ion-color-${colorName}-tint: ${Color(color).darken(this.tintRatio)};
           `;
    }

    contrast(color) {
        const ratio = 0.8;
        color = Color(color);
        return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
    }
}
