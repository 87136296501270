<ion-header>
    <app-topbar
        [title]="'ticket-pictures.title' | translate"
        [left]="leftIcon"
        (leftClicked)="leftFunction()"
        [right]="'close'"
        (rightClicked)="modalController.dismiss()"></app-topbar>
</ion-header>

<ion-content>
    <ion-row class="h-100 ion-align-items-center">
        <swiper-container pager="true" #swiper appSwiper [config]="slideOpts" class="h-100">
            <swiper-slide *ngFor="let image of images">
                <div class="swiper-zoom-container">
                    <img [src]="image.file" />
                </div>
            </swiper-slide>
        </swiper-container>
    </ion-row>
</ion-content>
