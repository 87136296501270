import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { LoadingController, NavController } from '@ionic/angular';
import { DataService } from '../../services/data/data.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { OriginService } from '../../services/origin/origin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PopupService } from '../../services/popup/popup.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-namespace-chooser',
    templateUrl: './namespace-chooser.page.html',
    styleUrls: ['./namespace-chooser.page.scss'],
})
export class NamespaceChooserPage implements OnInit {
    tokens: any[] = [];
    logoUrl: string;
    fromProfile: boolean = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private loadingController: LoadingController,
        private dataService: DataService,
        private navController: NavController,
        private translate: TranslateService,
        private originService: OriginService,
        private popupService: PopupService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.logoUrl = `${environment.apiBase}namespaces/image/logo.png?X-App-Origin=${this.originService.origin}`;

        this.subscriptions.push(
            this.route.queryParams.pipe(take(1)).subscribe((params) => {
                if (params) {
                    this.fromProfile = Boolean(params.fromProfile);
                    this.tokens = params.tokens ? JSON.parse(params.tokens) : [];
                }
            })
        );
    }

    async ngOnInit() {
        if (!this.tokens?.length) {
            this.tokens = this.authService.namespaceTokens$.value;
            if (!this.authService.hasValidMultiNamespaceTokens(this.tokens)) {
                await this.signIn(
                    this.tokens.find((namespaceToken) => namespaceToken?.token),
                    false
                );
            }
        }
    }

    async signIn(token, showLoading = true) {
        if (!token?.token) {
            await this.passwordSignIn();
        } else {
            const loading = await this.loadingController.create();

            if (!showLoading) {
                await loading.present();
            }

            const reload = Boolean(this.authService.currentNamespace);
            await this.authService.loginWithCustomToken(token.token);

            if (reload) {
                window.location.href = '/';
            }

            await this.dataService.initializeEssentialAppData();

            if (!showLoading) {
                await loading.dismiss();
            }

            await this.dataService.setupAfterLogin();
        }
    }

    private async passwordSignIn() {
        const loading = await this.loadingController.create();
        const alert = await this.popupService.showInputAlert(
            'auth.namespaceChooser.password-title',
            'auth.namespaceChooser.password-message',
            [
                {
                    text: this.translate.instant('general.cancel'),
                    handler: () => {
                        alert.dismiss(false);
                    },
                },
                {
                    text: this.translate.instant('general.confirm'),
                    handler: async (value) => {
                        await loading.present();
                        const password = value?.password?.trim();

                        if (password) {
                            try {
                                await this.authService.login(
                                    this.router.getCurrentNavigation()?.extras?.state?.email,
                                    password
                                );
                                await alert.dismiss(true);
                            } catch (err) {
                                await loading.dismiss();
                                await this.popupService.showToast(
                                    this.translate.instant('auth.namespaceChooser.password-invalid'),
                                    true
                                );
                            }
                        }

                        return false;
                    },
                },
            ],

            [
                {
                    name: 'password',
                    type: 'password',
                    attributes: {
                        type: 'password',
                        required: true,
                        autofocus: true,
                    },
                    placeholder: this.translate.instant('auth.namespaceChooser.password-label'),
                },
            ]
        );

        const result = (await alert.onDidDismiss()).data;
        await loading?.dismiss();

        if (result) {
            await this.dataService.initializeEssentialAppData();
            await this.dataService.setupAfterLogin();
        }
    }

    async closePage() {
        await this.navController.navigateBack('main/profile');
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }

        this.subscriptions = [];
    }
}
