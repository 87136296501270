import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { LoginPage } from './login.page';
import { TranslateModule } from '@ngx-translate/core';
import { NoDataComponentModule } from '../../components/molecules/no-data/no-data.component.module';
import { ChangePasswordComponentModule } from '../../modals/change-password/change-password.component.module';
import { ComponentsSharedModule } from '../../components/components.shared.module';

const routes: Routes = [
    {
        path: '',
        component: LoginPage,
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        RouterModule.forChild(routes),
        NoDataComponentModule,
        ChangePasswordComponentModule,
        ComponentsSharedModule,
    ],
    declarations: [LoginPage],
})
export class LoginPageModule {}
