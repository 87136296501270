import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    constructor(
        private toastController: ToastController,
        private alertController: AlertController,
        private translate: TranslateService
    ) {}

    async showToast(message, isError?, options?: { duration?: number; position?: 'top' | 'bottom' | 'middle' }) {
        const opts: {
            message: string;
            duration?: number;
            position?: 'top' | 'bottom' | 'middle';
            color: string;
        } = {
            message,
            duration: 3000,
            color: isError ? 'danger' : undefined,
            position: 'top',
        };
        if (options) {
            if (options.duration) {
                opts.duration = options.duration;
            }
            if (options.position) {
                opts.position = options.position;
            }
        }
        const tc = await this.toastController.create(opts);

        await tc.present();
        return tc;
    }

    async showAlert(title, message, buttons) {
        const alert = await this.alertController.create({
            header: title,
            buttons,
            message,
            backdropDismiss: false,
        });
        return alert.present();
    }

    async showInputAlert(title, message, buttons, inputs) {
        const alert = await this.alertController.create({
            header: title,
            buttons,
            message,
            inputs,
        });
        await alert.present();
        return alert;
    }

    showConfirm(message, noButton = 'general.cancel', yesButton = 'general.yes') {
        return new Promise((resolve) => {
            this.showAlert('', this.translate.instant(message), [
                {
                    text: this.translate.instant(noButton),
                    handler: () => {
                        resolve(false);
                    },
                },
                {
                    text: this.translate.instant(yesButton),
                    handler: () => {
                        resolve(true);
                    },
                },
            ]);
        });
    }

    showInfo(translationKey, okButton = 'general.ok') {
        return new Promise((resolve) => {
            this.showAlert('', this.translate.instant(translationKey), [
                {
                    text: this.translate.instant(okButton),
                    handler: () => {
                        resolve(true);
                    },
                },
            ]);
        });
    }
}
