import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { ElasticSearchComponent } from './elastic-search.component';

@NgModule({
    declarations: [ElasticSearchComponent],
    exports: [ElasticSearchComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule],
})
export class ElasticSearchComponentModule {}
