import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { firstValueFrom, Observable } from 'rxjs';
import { Property } from '../../models/property';
import { DocumentsRequests } from '../../util/documents-requests';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { collection, doc, docData, Firestore, getDoc, getDocs, query, where } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    private properties = {};

    constructor(
        private userService: UserService,
        private firestore: Firestore,
        private http: HttpClient,
        private documentsRequests: DocumentsRequests,
        private apiService: ApiService
    ) {}

    async getPropertyById(id: string): Promise<any> {
        return await firstValueFrom(this.http.get(`${environment.apiBase}properties/${id}`));
    }

    getPropertyObservable(id: string): Observable<any> {
        const propertyDoc = doc(this.firestore, `ns/${this.userService.getNamespace()}/properties/${id}`);
        return docData(propertyDoc, { idField: 'id' });
    }

    async getPropertiesByIds(ids: string[]): Promise<any[]> {
        return (await this.apiService.get(`properties?id=${ids.join(',')}`)) as any[];
    }

    async uploadFiles(documents: { pdfs: any[]; imgs: any[] }, propertyId: string, path = '') {
        if (!documents || !documents.imgs || !documents.pdfs) {
            return null;
        } else if (documents.imgs.length || documents.pdfs.length) {
            return this.documentsRequests.upload(
                'properties',
                propertyId,
                [...documents.imgs, ...documents.pdfs],
                path
            );
        } else {
            return null;
        }
    }

    async renameFile(propertyId: string, docType: 'imgs' | 'pdfs', index: number, docName: string) {
        return this.documentsRequests.rename('properties', propertyId, docType, index, docName);
    }

    async deletePropertyFile(propertyId: string, index: number, fileType: 'imgs' | 'pdfs') {
        return this.documentsRequests.delete('properties', propertyId, fileType, index);
    }

    async getProperty(propertyId: string): Promise<any> {
        if (this.properties[propertyId]) {
            return await this.properties[propertyId];
        }
        this.properties[propertyId] = new Promise(async (resolve) => {
            const docRef = doc(this.firestore, `ns/${this.userService.getNamespace()}/properties/${propertyId}`);
            const docSnap = await getDoc(docRef);
            const property: Property = docSnap.data() as Property;
            this.properties[propertyId] = property;
            resolve(property);
        });
        return this.properties[propertyId];
    }

    async getPropertiesOfServiceProvider(serviceProviderId: string): Promise<Property[]> {
        const propertiesCollectionRef = collection(this.firestore, 'properties');
        const q = query(propertiesCollectionRef, where('serviceProviderId', '==', serviceProviderId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => doc.data() as Property);
    }
}
