export enum TicketStatus {
    INIT = 'INIT',
    ACCEPTED = 'ACCEPTED',
    IN_WORK = 'IN_WORK',
    IN_PROGRESS = 'IN_WORK',
    OFFERS = 'OFFERS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    DECLINED = 'DECLINED',
    CM_ACCEPTED = 'CM_ACCEPTED',
    CM_OFFERS = 'CM_OFFERS',
    CM_DATE_SET = 'CM_DATE_SET',
    CM_IN_WORK = 'CM_IN_WORK',
    CM_OFFERS_FINISHED = 'CM_OFFERS_FINISHED',
    CM_FINISHED = 'CM_FINISHED',
    CLOSED = 'CLOSED',
}

export enum OfferStatus {
    OFFER = 'offer',
    INIT = 'init',
    OFFER_SET = 'offerset',
    ACCEPTED = 'accepted',
    DATE_SET = 'dateset',
    IN_WORK = 'inwork',
    DECLINED = 'declined',
    FINISHED = 'finished',
    CANCELED = 'canceled',
}

export enum TicketTypes {
    REQUEST = 'REQUEST',
    HANDOVER = 'HANDOVER',
    ACQUISITION = 'ACQUISITION',
    UAPPLICATION = 'UAPPLICATION',
    TASK = 'TASK',
}
