import { NgModule } from '@angular/core';
import { NoDataComponent } from './no-data.component';
import { ComponentsSharedModule } from '../../components.shared.module';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [NoDataComponent],
    exports: [NoDataComponent],
})
export class NoDataComponentModule {}
