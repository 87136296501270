import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { NamespaceInfo } from '../../models/namespace-info';
import { BaseDataService } from '../../util/base-data-service';
import { ApiService } from '../api/api.service';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class NamespaceService implements BaseDataService {
    private namespace: NamespaceInfo;
    public ticketConfig: any;
    public flatTypeConfig: any;

    constructor(
        private userService: UserService,
        private firestore: Firestore,
        private api: ApiService
    ) {}

    get(): NamespaceInfo {
        return this.namespace;
    }

    async initialize(): Promise<NamespaceInfo> {
        [this.ticketConfig, this.flatTypeConfig] = await Promise.all([
            (await this.getTicketConfiguration()).data(),
            this.getFlatTypeConfiguration(),
        ]);
        if (this.namespace) {
            return Promise.resolve(this.namespace);
        }
        const docRef = doc(this.firestore, `ns/${this.userService.getNamespace()}`);
        const docSnap = await getDoc(docRef);
        this.namespace = docSnap.data() as NamespaceInfo;
        return this.namespace;
    }

    async getNsSplashLogo(): Promise<string> {
        if (this.namespace) {
            return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
        }

        if (!this.userService.user) {
            await this.userService.getUser();
        }
        await this.initialize();
        return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
    }

    async getTicketConfiguration() {
        const docRef = doc(this.firestore, `ns/${this.userService.getNamespace()}/configurations/tickets`);
        const docSnap = await getDoc(docRef);
        return docSnap;
    }

    async getFlatTypeConfiguration(): Promise<any> {
        const docRef = doc(this.firestore, `ns/${this.userService.getNamespace()}/configurations/flatTypes`);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
    }

    async getDomain(): Promise<string> {
        const response = await this.api.get('namespaces/domain');
        return response?.domain || null;
    }

    terminate(): void {
        this.namespace = null;
    }
}
