import { Injectable } from '@angular/core';
import { AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class OriginService {
    private nativeAppMapping = {
        'ch.woonig.manager': 'woonig.app',
        'ch.woonig.manager.cip': 'ci-platform.app',
        'ch.woonig.manager.dl': 'dl.woonig.app',
        'ch.woonig.manager.emeria': 'linkathome.app',
    };

    private urlMapping = {
        'https://manager.woonig.app': 'woonig.app',
        'https://test.manager.woonig.app': 'woonig.app',
        'https://manager.ci-platform.app': 'ci-platform.app',
        'https://test.manager.ci-platform.app': 'ci-platform.app',
        'https://manager.linkathome.app': 'linkathome.app',
        // 'http://localhost:port': 'linkathome.app', for local debugging on emeria
    };

    private whiteLabelMapping = {
        'linkathome.app': 'linkathome',
    };

    public pluginReady = false;
    public origin: string = null;
    public whiteLabelKey: string = null;

    constructor() {}

    async initialize(appInfo: AppInfo): Promise<string> {
        if (appInfo.id) {
            this.origin = this.nativeAppMapping[appInfo.id || 'ch.woonig.tenant'];
        } else if (!Capacitor.isNativePlatform()) {
            this.origin = this.urlMapping[window.location.origin] || 'woonig.app';
        }

        this.whiteLabelKey = this.whiteLabelMapping[this.origin] || null;
        this.pluginReady = true;
        return this.origin;
    }

    isLinkAtHome() {
        return this.origin === 'linkathome.app';
    }
}
