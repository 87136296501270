import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { OfferAppointmentSelectionComponent } from './offer-appointment-selection.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ElasticSearchComponentModule } from '../elastic-search/elastic-search.component.module';

@NgModule({
    declarations: [OfferAppointmentSelectionComponent],
    exports: [OfferAppointmentSelectionComponent],
    imports: [
        ComponentsSharedModule,
        TopbarComponentModule,
        ReactiveFormsModule,
        ElasticSearchComponentModule,
        FormsModule,
    ],
    providers: [],
})
export class OfferAppointmentSelectionComponentModule {}
