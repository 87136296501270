import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { collection, collectionData, Firestore, query, where } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { convertFirestoreDate } from 'src/app/util/util';

@Injectable({
    providedIn: 'root',
})

/**
 * Service class for legal documents to handle data access with the api and firestore
 *
 */
export class LegalDocumentsService {
    private documentNames = ['privacyPolicy', 'termsOfUse'];
    constructor(
        private firestore: Firestore,
        private apiService: ApiService
    ) {}

    /**
     * Used to get observable of legal documents
     * Dates in the result will already be converted
     *
     * @returns Observable of the "legalDocuments" document in firestore
     */
    public getLegalDocumentsObservable() {
        const collectionRef = collection(this.firestore, 'public');
        const q = query(collectionRef, where('id', '==', 'legalDocuments'));
        return collectionData(q).pipe(
            map((documentArray: any[]) => {
                const document = documentArray[0];
                convertFirestoreDate(document);
                return document;
            })
        );
    }

    /**
     * Get latest version of legalDocuments
     *
     * @returns Observable of the "legalDocuments" (latest version)
     */
    public getLatestLegalDocumentsObservable() {
        return this.getLegalDocumentsObservable().pipe(
            map((legalDocument) => {
                for (const documentName of this.documentNames) {
                    if (legalDocument[documentName]) {
                        legalDocument[documentName] =
                            legalDocument[documentName][legalDocument[documentName].length - 1];
                    }
                }

                for (const domain of ['woonig', 'cip']) {
                    for (const documentName of this.documentNames) {
                        if (legalDocument[domain][documentName]) {
                            legalDocument[domain][documentName] =
                                legalDocument[domain][documentName][legalDocument[domain][documentName].length - 1];
                        }
                    }
                }

                return legalDocument;
            })
        );
    }

    /**
     * Send a post request to the api to accept the given  legal documents
     * Data will be sent as JSON
     *
     * @param acceptedLegalDocuments object of accepted legal documents
     */
    public async setLegalDocumentsAccepted(acceptedLegalDocuments: any) {
        await this.apiService.post('legalDocuments/accept', {
            legalDocuments: acceptedLegalDocuments,
        });
    }
}
