import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DocumentUtilService } from '../document-util/document-util.service';
import { User } from '../../models/user';
import { Theme } from '../../models/theme';
import { Manager } from '../../models/manager';
import { DocumentsRequests } from '../../util/documents-requests';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { OriginService } from '../origin/origin.service';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { Auth, authState, User as FirebaseUser } from '@angular/fire/auth';
import { doc, docData, Firestore, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: Manager = null;
    user$: BehaviorSubject<Manager> = new BehaviorSubject<Manager>(undefined);
    userLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    profilePicture: string = null;
    namespace: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    tenantsCache = {};
    firebaseUser: FirebaseUser;
    moduleSubscription = new BehaviorSubject<string[]>([]);

    constructor(
        private firestore: Firestore,
        private afAuth: Auth,
        private documentUtilService: DocumentUtilService,
        private http: HttpClient,
        private documentsRequests: DocumentsRequests,
        private loadingController: LoadingController,
        private pushNotificationService: PushNotificationService,
        private apiService: ApiService,
        private translate: TranslateService,
        private originService: OriginService
    ) {
        authState(this.afAuth)
            .pipe(
                switchMap(async (user: FirebaseUser) => {
                    if (user) {
                        this.firebaseUser = user;
                        await this.pushNotificationService.initialize();
                        try {
                            const idTokenResult = await user.getIdTokenResult();
                            return { idTokenResult, user };
                        } catch {
                            return { idTokenResult: of(null), user };
                        }
                    } else {
                        this.firebaseUser = null;
                        return { idTokenResult: of(null), user };
                    }
                })
            )
            .pipe(
                switchMap((result: any) => {
                    if (result?.idTokenResult?.claims) {
                        if (result.idTokenResult.claims.pm && result.idTokenResult.claims.ns) {
                            this.namespace.next(result.idTokenResult.claims.ns);
                            this.moduleSubscription.next(result.idTokenResult.claims.modules || []);
                            return docData(
                                doc(this.firestore, `ns/${this.getNamespace()}/propertyManagers/${result.user.uid}`)
                            );
                        } else {
                            console.warn('Claims do not fit for uid: ' + result.user.uid);
                            return of(null);
                        }
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe((user?: Manager) => {
                if (user) {
                    this.user = user;
                    this.user$.next(user);
                } else {
                    this.user = null;
                    this.namespace.next(null);
                    this.moduleSubscription.next([]);
                    this.user$.next(null);
                }
            });
    }

    async getToken(): Promise<string> {
        if (this.firebaseUser) {
            return this.firebaseUser.getIdToken();
        }
        return null;
    }

    getUser(): Promise<Manager> {
        if (this.user) {
            return Promise.resolve(this.user);
        }
        return new Promise((resolve) => {
            const userSubscription = this.user$.subscribe((newUser) => {
                if (newUser) {
                    resolve(this.user);
                    userSubscription.unsubscribe();
                }
            });
        });
    }

    async getTenantUser(tenantId: string): Promise<User> {
        if (this.tenantsCache[tenantId]) {
            return Promise.resolve(this.tenantsCache[tenantId]);
        }

        const tenantDocRef = doc(this.firestore, `users/${tenantId}`);
        return getDoc(tenantDocRef).then((documentObject) => {
            const tenant = documentObject.data() as User;
            this.tenantsCache[tenantId] = tenant;
            return tenant;
        });
    }

    getTenantObservable(id: string) {
        const tenantDocRef = doc(this.firestore, `users/${id}`);
        return docData(tenantDocRef);
    }

    async getTenantsByIds(ids: string[]) {
        return (await this.apiService.get(`users?id=${ids.join(',')}`)) as any[];
    }

    getNamespace(): string {
        return this.namespace.getValue();
    }

    getNamespaceObservable(): Observable<string> {
        return this.namespace.asObservable();
    }

    updateUser(id, data): Promise<any> {
        data.id = id;
        return this.apiService.put(`managers`, data);
    }

    async setLanguage(lang: string, noSave?): Promise<any> {
        await this.localeInitializer(lang);
        if (noSave) {
            this.userLanguage$.next(lang);
        }
        if (!this.user) {
            return Promise.reject();
        } else if (lang === this.user.language) {
            return Promise.resolve();
        }
        if (!noSave) {
            const loader = await this.loadingController.create();
            await loader.present();
            await this.http.put(`${environment.apiBase}managers/${this.user.id}/language/${lang}`, null).toPromise();
            this.userLanguage$.next(lang);
            await loader.dismiss();
        }
    }

    async localeInitializer(localeId: string): Promise<void> {
        try {
            let localeModule: any;

            switch (localeId) {
                case 'hy':
                    localeModule = await import('@angular/common/locales/hy');
                    break;
                case 'pa':
                    localeModule = await import('@angular/common/locales/pa');
                    break;
                case 'no':
                    localeModule = await import('@angular/common/locales/no');
                    break;
                case 'ms':
                    localeModule = await import('@angular/common/locales/ms');
                    break;
                case 'bg':
                    localeModule = await import('@angular/common/locales/bg');
                    break;
                case 'sw':
                    localeModule = await import('@angular/common/locales/sw');
                    break;
                case 'el':
                    localeModule = await import('@angular/common/locales/el');
                    break;
                case 'ka':
                    localeModule = await import('@angular/common/locales/ka');
                    break;
                case 'hu':
                    localeModule = await import('@angular/common/locales/hu');
                    break;
                case 'ku':
                    localeModule = await import('@angular/common/locales/ku');
                    break;
                case 'ky':
                    localeModule = await import('@angular/common/locales/ky');
                    break;
                case 'lo':
                    localeModule = await import('@angular/common/locales/lo');
                    break;
                case 'lb':
                    localeModule = await import('@angular/common/locales/lb');
                    break;
                case 'si':
                    localeModule = await import('@angular/common/locales/si');
                    break;
                case 'vi':
                    localeModule = await import('@angular/common/locales/vi');
                    break;
                case 'uz':
                    localeModule = await import('@angular/common/locales/uz');
                    break;
                case 'en':
                    localeModule = await import('@angular/common/locales/en');
                    break;
                case 'es':
                    localeModule = await import('@angular/common/locales/es');
                    break;
                case 'cs':
                    localeModule = await import('@angular/common/locales/cs');
                    break;
                case 'da':
                    localeModule = await import('@angular/common/locales/da');
                    break;
                case 'ja':
                    localeModule = await import('@angular/common/locales/ja');
                    break;
                case 'mn':
                    localeModule = await import('@angular/common/locales/mn');
                    break;
                case 'my':
                    localeModule = await import('@angular/common/locales/my');
                    break;
                case 'pl':
                    localeModule = await import('@angular/common/locales/pl');
                    break;
                case 'ta':
                    localeModule = await import('@angular/common/locales/ta');
                    break;
                case 'so':
                    localeModule = await import('@angular/common/locales/so');
                    break;
                case 'xh':
                    localeModule = await import('@angular/common/locales/xh');
                    break;
                case 'sv':
                    localeModule = await import('@angular/common/locales/sv');
                    break;
                case 'ca':
                    localeModule = await import('@angular/common/locales/ca');
                    break;
                case 'pt':
                    localeModule = await import('@angular/common/locales/pt');
                    break;
                case 'nl':
                    localeModule = await import('@angular/common/locales/nl');
                    break;
                case 'de':
                    localeModule = await import('@angular/common/locales/de');
                    break;
                case 'az':
                    localeModule = await import('@angular/common/locales/az');
                    break;
                case 'fr':
                    localeModule = await import('@angular/common/locales/fr');
                    break;
                case 'sr':
                    localeModule = await import('@angular/common/locales/sr');
                    break;
                case 'am':
                    localeModule = await import('@angular/common/locales/am');
                    break;
                case 'ur':
                    localeModule = await import('@angular/common/locales/ur');
                    break;
                case 'it':
                    localeModule = await import('@angular/common/locales/it');
                    break;
                case 'id':
                    localeModule = await import('@angular/common/locales/id');
                    break;
                case 'ga':
                    localeModule = await import('@angular/common/locales/ga');
                    break;
                case 'lt':
                    localeModule = await import('@angular/common/locales/lt');
                    break;
                case 'af':
                    localeModule = await import('@angular/common/locales/af');
                    break;
                case 'ne':
                    localeModule = await import('@angular/common/locales/ne');
                    break;
                case 'tg':
                    localeModule = await import('@angular/common/locales/tg');
                    break;
                case 'ps':
                    localeModule = await import('@angular/common/locales/ps');
                    break;
                case 'ru':
                    localeModule = await import('@angular/common/locales/ru');
                    break;
                case 'bn':
                    localeModule = await import('@angular/common/locales/bn');
                    break;
                case 'bs':
                    localeModule = await import('@angular/common/locales/bs');
                    break;
                case 'km':
                    localeModule = await import('@angular/common/locales/km');
                    break;
                case 'tr':
                    localeModule = await import('@angular/common/locales/tr');
                    break;
                case 'et':
                    localeModule = await import('@angular/common/locales/et');
                    break;
                case 'fi':
                    localeModule = await import('@angular/common/locales/fi');
                    break;
                case 'is':
                    localeModule = await import('@angular/common/locales/is');
                    break;
                case 'lv':
                    localeModule = await import('@angular/common/locales/lv');
                    break;
                case 'mk':
                    localeModule = await import('@angular/common/locales/mk');
                    break;
                case 'mg':
                    localeModule = await import('@angular/common/locales/mg');
                    break;
                case 'mi':
                    localeModule = await import('@angular/common/locales/mi');
                    break;
                case 'ko':
                    localeModule = await import('@angular/common/locales/ko');
                    break;
                case 'zu':
                    localeModule = await import('@angular/common/locales/zu');
                    break;
                case 'sq':
                    localeModule = await import('@angular/common/locales/sq');
                    break;
                case 'ar':
                    localeModule = await import('@angular/common/locales/ar');
                    break;
                case 'be':
                    localeModule = await import('@angular/common/locales/be');
                    break;
                case 'hr':
                    localeModule = await import('@angular/common/locales/hr');
                    break;
                case 'sk':
                    localeModule = await import('@angular/common/locales/sk');
                    break;
                case 'hi':
                    localeModule = await import('@angular/common/locales/hi');
                    break;
                case 'fa':
                    localeModule = await import('@angular/common/locales/fa');
                    break;
                case 'he':
                    localeModule = await import('@angular/common/locales/he');
                    break;
                case 'kk':
                    localeModule = await import('@angular/common/locales/kk');
                    break;
                case 'mt':
                    localeModule = await import('@angular/common/locales/mt');
                    break;
                case 'ro':
                    localeModule = await import('@angular/common/locales/ro');
                    break;
                case 'sl':
                    localeModule = await import('@angular/common/locales/sl');
                    break;
                case 'th':
                    localeModule = await import('@angular/common/locales/th');
                    break;
                case 'uk':
                    localeModule = await import('@angular/common/locales/uk');
                    break;
                case 'sn':
                    localeModule = await import('@angular/common/locales/sn');
                    break;
                case 'cy':
                    localeModule = await import('@angular/common/locales/cy');
                    break;
                case 'eo':
                    localeModule = await import('@angular/common/locales/eo');
                    break;
                case 'eu':
                    localeModule = await import('@angular/common/locales/eu');
                    break;
                case 'fy':
                    localeModule = await import('@angular/common/locales/fy');
                    break;
                case 'gd':
                    localeModule = await import('@angular/common/locales/gd');
                    break;
                case 'gl':
                    localeModule = await import('@angular/common/locales/gl');
                    break;
                case 'gu':
                    localeModule = await import('@angular/common/locales/gu');
                    break;
                case 'ha':
                    localeModule = await import('@angular/common/locales/ha');
                    break;
                case 'haw':
                    localeModule = await import('@angular/common/locales/haw');
                    break;
                case 'ig':
                    localeModule = await import('@angular/common/locales/ig');
                    break;
                case 'kn':
                    localeModule = await import('@angular/common/locales/kn');
                    break;
                case 'ml':
                    localeModule = await import('@angular/common/locales/ml');
                    break;
                case 'mr':
                    localeModule = await import('@angular/common/locales/mr');
                    break;
                case 'sd':
                    localeModule = await import('@angular/common/locales/sd');
                    break;
                case 'su':
                    localeModule = await import('@angular/common/locales/su');
                    break;
                case 'yi':
                    localeModule = await import('@angular/common/locales/yi');
                    break;
                case 'yo':
                    localeModule = await import('@angular/common/locales/yo');
                    break;
                case 'zh-TW':
                    localeModule = await import('@angular/common/locales/zh');
                    break;
                default:
                    localeModule = await import('@angular/common/locales/en');
                    console.warn(`Locale data for '${localeId}' not found, using 'en' as fallback.`);
            }

            registerLocaleData(localeModule.default);
        } catch (error) {
            console.error(`Error loading locale data for '${localeId}':`, error);
        }
    }

    async setVacation(type: string): Promise<any> {
        return await this.http
            .post(`${environment.apiBase}managers/${this.user.id}/vacation/${type}`, null)
            .toPromise();
    }

    async updateEmail(): Promise<any> {
        if (!this.user) {
            return Promise.reject();
        }
        const loader = await this.loadingController.create();
        await loader.present();
        try {
            await this.http
                .put(`${environment.apiBase}managers/${this.user.uid}/email/${this.user.email}`, null)
                .toPromise();
            await loader.dismiss();
        } catch (error) {
            console.error('error changing email', error);
            await loader.dismiss();
            throw error;
        }
    }

    async updateProfilePicture(img: string) {
        const downloadUrl = await this.documentUtilService.documentUpload(
            img,
            `ns/${this.getNamespace()}/profile/${this.user.id}/profilePicture.jpeg`,
            'img'
        );
        await updateDoc(doc(this.firestore, `ns/${this.getNamespace()}/propertyManagers/${this.user.id}`), {
            profilePicture: downloadUrl,
        });
    }

    deleteProfilePicture() {
        const docRef = doc(this.firestore, `ns/${this.getNamespace()}/propertyManagers/${this.user.id}`);
        updateDoc(docRef, { profilePicture: null });
    }

    async uploadFiles(documents: { pdfs: any[]; imgs: any[] }, uid: string, path = '') {
        if (!documents || !documents.imgs || !documents.pdfs) {
            return null;
        } else if (documents.imgs.length || documents.pdfs.length) {
            return this.documentsRequests.upload('users', uid, [...documents.imgs, ...documents.pdfs], path);
        } else {
            return null;
        }
    }

    async getManager(managerId: string): Promise<any> {
        return await this.http.get(`${environment.apiBase}managers/${managerId}`).toPromise();
    }

    async renameFile(uid: string, docType: 'imgs' | 'pdfs', index: number, docName: string) {
        return this.documentsRequests.rename('users', uid, docType, index, docName);
    }

    async deletePersonalFile(uid: string, index: number, fileType: 'imgs' | 'pdfs') {
        return this.documentsRequests.delete('users', uid, fileType, index);
    }

    getTheme(): Promise<Theme> {
        const docRef = doc(this.firestore, `ns/${this.getNamespace()}`);
        return getDoc(docRef).then((ns) => {
            return ns.data().theme as Theme;
        });
    }

    setNotificationBlocker(value: boolean) {
        const docRef = doc(this.firestore, `ns/${this.getNamespace()}/propertyManagers/${this.user.id}`);
        return setDoc(docRef, { notify: value }, { merge: true });
    }

    getUserFullName(user?: any) {
        const userObj = user || this.user$.value;

        return userObj.firstname ? `${userObj.firstname} ${userObj.lastname}` : userObj.lastname;
    }

    async getBadges(): Promise<{
        appStore: { url: string; img: string };
        playStore: { url: string; img: string };
    }> {
        const origin = this.originService.origin;
        const [appStore, playStore] = await Promise.all([
            this.apiService.get(`managers/${origin}/appstore`),
            this.apiService.get(`managers/${origin}/playstore`),
        ]);

        appStore.img = 'https://storage.googleapis.com/public_woonig_assets/images/app_store_badge.png';
        playStore.img = `https://play.google.com/intl/en/badges/images/generic/${this.translate.currentLang}_badge_web_generic.png`;

        return appStore?.url && playStore?.url ? { appStore, playStore } : null;
    }

    /**
     * returns push and email notification settings of user
     */
    public getNotificationSetting(): { push: boolean; email: boolean } {
        const push = this.user.pushNotifications;
        const email = this.user.emailNotifications;

        return {
            push: !push ? true : !!push.length,
            email: !email ? true : !!email.length,
        };
    }

    /**
     * sets push notification settings
     * @param value new value of push notification settings
     */
    public async setPushNotifications(value: boolean): Promise<any> {
        return await this.apiService.put('managers', {
            id: this.user.id,
            pushNotifications: value ? ['all'] : [],
        });
    }

    /**
     * sets email notification settings
     * @param value new value of email notification settings
     */
    public async setEmailNotifications(value: boolean): Promise<any> {
        return await this.apiService.put('managers', {
            id: this.user.id,
            emailNotifications: value ? ['all'] : [],
        });
    }
}
