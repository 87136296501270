import { Injectable } from '@angular/core';
import { TextObjAggregated } from '../../models/text-obj-aggregated';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TextObj } from '../../models/text-obj';
import { UserService } from '../user/user.service';
import { BaseDataService } from '../../util/base-data-service';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class TextService implements BaseDataService {
    private sub: Subscription;
    texts$: BehaviorSubject<TextObjAggregated> = new BehaviorSubject<TextObjAggregated>({});

    constructor(
        private firestore: Firestore,
        private userService: UserService
    ) {}

    initialize() {
        return new Promise<void>((resolve) => {
            const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTreeTexts`);
            this.sub = collectionData(collectionRef, { idField: 'id' })
                .pipe(
                    map((snaps) => {
                        return snaps.reduce((acc, snap) => {
                            acc[snap.id] = snap as TextObj;
                            return acc;
                        }, {} as TextObjAggregated);
                    })
                )
                .subscribe((texts: TextObjAggregated) => {
                    this.texts$.next(texts);
                    resolve();
                });
        });
    }

    get texts(): Observable<TextObjAggregated> {
        return this.texts$.asObservable();
    }

    terminate() {
        this.sub.unsubscribe();
    }

    getTexts() {
        if (Object.keys(this.texts$.getValue()).length) {
            return this.texts$.getValue();
        }
        return new Promise((resolve) => {
            const sub = this.texts$.subscribe((texts) => {
                if (Object.keys(texts).length) {
                    resolve(texts);
                    sub.unsubscribe();
                }
            });
        });
    }
}
