import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TextService } from '../text/text.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { TextObjAggregated } from '../../models/text-obj-aggregated';
import { BaseDataService } from '../../util/base-data-service';
import { UserService } from '../user/user.service';
import { FlatService } from '../flat/flat.service';
import { flatten } from 'lodash';
import { ServiceProvider } from '../../models/serviceProvider';
import { Flat } from '../../models/flat';
import { Property } from '../../models/property';
import { Organisation } from '../../models/organisation';

export interface SearchParams {
    searchString: string;
    size?: number;
    from?: number;
    filter?: ElasticFilter;
    rangeFilter?: any;
    excludes?: Record<string, any>;
    includes?: string[];
    sort?: Record<string, any>;
    orderBy?: Record<string, any>;
    filterActive?: boolean;
    source?: boolean;
}

export interface ElasticFilter {
    [key: string]: any;
}

@Injectable({
    providedIn: 'root',
})
export class ElasticService implements BaseDataService {
    private texts: TextObjAggregated;
    private textSub: Subscription;

    constructor(
        private userService: UserService,
        private httpClient: HttpClient,
        private textService: TextService,
        private flatService: FlatService
    ) {}

    async initialize() {
        this.textSub = this.textService.texts$.subscribe((textObj) => {
            this.texts = textObj;
        });
    }

    setDefaultSearchParams(searchParams: SearchParams) {
        searchParams.size = searchParams.size || 10;
        searchParams.from = searchParams.from || 0;
        searchParams.filter = searchParams.filter || {};
        searchParams.excludes = searchParams.excludes || {};
        searchParams.includes = searchParams.includes || [];
        searchParams.sort = searchParams.sort || {};
        searchParams.source = searchParams.source || true;
        searchParams.rangeFilter = searchParams.rangeFilter || {};
        searchParams.filterActive = searchParams.filterActive || true;
    }

    async searchUser(searchParams: SearchParams) {
        this.setDefaultSearchParams(searchParams);
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchUser`, searchParams)
        );
        return this.mapResult(res);
    }

    async searchFlat(searchParams: SearchParams): Promise<Flat[]> {
        this.setDefaultSearchParams(searchParams);
        const result: any = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchFlat`, searchParams)
        );

        return await this.flatService.getFlatsByIds((result?.hits?.hits || []).map((hit) => hit._id));
    }

    async searchProperty(searchParams: SearchParams): Promise<Property[]> {
        this.setDefaultSearchParams(searchParams);
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchProperty`, searchParams)
        );
        return this.mapResult(res);
    }

    async searchGroups(searchParams: SearchParams): Promise<any> {
        this.setDefaultSearchParams(searchParams);
        searchParams.filterActive = false;
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchGroups`, searchParams)
        );
        return this.mapResult(res);
    }

    public async searchCraftsman(searchParams: SearchParams): Promise<ServiceProvider[]> {
        this.setDefaultSearchParams(searchParams);
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchCraftsman`, searchParams)
        );
        return this.mapResult(res);
    }

    public async searchPropertyManager(searchParams: SearchParams) {
        this.setDefaultSearchParams(searchParams);
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchPropertyManager`, searchParams)
        );
        return this.mapResult(res);
    }

    public async searchTickets(searchParams: SearchParams) {
        const language: string = this.userService.userLanguage$.value;
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchTickets`, {
                ...searchParams,
                language: language,
            })
        );
        const searchResult = this.mapResult(res);
        searchResult.forEach((searchResultObject: any) => {
            if (Array.isArray(searchResultObject.hashtags)) {
                searchResultObject.hashtags.forEach((tag) => {
                    if (tag.descriptionId) {
                        tag.description = this.texts[tag.descriptionId];
                    }
                    if (tag.textId) {
                        tag.text = this.texts[tag.textId];
                    }
                });
            }
        });
        return searchResult;
    }

    async searchOrganisations(searchParams: SearchParams): Promise<Organisation[]> {
        this.setDefaultSearchParams(searchParams);
        searchParams.filterActive = false;
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchOrganisations`, searchParams)
        );
        return this.mapResult(res);
    }

    public async searchTicketNotes(searchObj: any) {
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchTicketNotes`, {
                ...searchObj,
            })
        );
        return this.prettifyResult(res);
    }

    public async searchOffers(searchObj: any) {
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchOffers`, {
                ...searchObj,
            })
        );
        return this.prettifyResult(res);
    }

    public async getTicketsCount(filter: any) {
        const response: any = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchTickets`, {
                filter,
                source: false,
                size: 1,
            })
        );

        return response.hits.total.value;
    }

    public async searchTicketTexts(searchObj: any) {
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticSearch/searchTicketTexts`, {
                ...searchObj,
            })
        );
        return this.mapResult(res);
    }

    public async getNotifications(filter?: any, from?: any, size = 10) {
        const response: any = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchNotifications`, {
                filter: {
                    ...filter,
                    'managerId.keyword': this.userService.user.id,
                },
                source: false,
                sort: { createdOn: 'desc' },
                from,
                size,
            })
        );

        return {
            hits: response.hits.hits.map((hit) => hit._id),
            docs: response.hits.hits.map((s) => s._source),
            total: response.hits.total.value,
        };
    }

    public async getNotificationsCount(filter?: any) {
        const response: any = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchNotifications`, {
                filter: {
                    ...filter,
                    'managerId.keyword': this.userService.user.id,
                },
                source: false,
                size: 0,
            })
        );

        return response.hits.total.value;
    }

    async searchBlackboard(searchParams: SearchParams) {
        this.setDefaultSearchParams(searchParams);
        try {
            const response: any = await lastValueFrom(
                this.httpClient.post(`${environment.apiBase}elasticsearch/searchBlackboard`, searchParams)
            );

            const result: any = {
                total: response.hits.total.value,
                ids: response.hits.hits.map((hit) => hit._id),
            };
            if (searchParams.source) {
                result.source = response.hits.hits.map((hit) => hit._source);
            }
            return result;
        } catch (e) {
            return {
                total: 0,
                ids: [],
            };
        }
    }

    public async searchBlackboardComments(searchObj: any) {
        const res = await lastValueFrom(
            this.httpClient.post(`${environment.apiBase}elasticsearch/searchBlackboardComments`, {
                ...searchObj,
            })
        );
        return this.prettifyResult(res);
    }

    /**
     *  searchString: string,
     *         size = 10,
     *         from = 0,
     *         filter = {},
     *         excludes = {},
     *         includes = [],
     *         orderBy = {}
     */
    async searchPropertiesByGroupId(groupId: string): Promise<Property[]> {
        const searchParams: SearchParams = {
            searchString: '',
            size: 1000,
            from: 0,
            filter: { 'groupId.keyword': groupId },
        };
        this.setDefaultSearchParams(searchParams);
        return this.searchProperty(searchParams);
    }

    async searchPropertiesByOrganisationId(organisationId: string): Promise<Property[]> {
        const elasticResponse = await Promise.all(
            [1, 2, 3, 4, 5].map((i): Promise<Property[]> => {
                return this.searchProperty({
                    searchString: '',
                    size: 1000,
                    from: 0,
                    filter: {
                        [`organisation0${i}Id.keyword`]: organisationId,
                    },
                });
            })
        );
        const result = flatten(elasticResponse);
        const uniqueIds = Array.from(new Set(result.map((property) => property.id)));
        return uniqueIds.map((id) => result.find((property) => property.id === id));
    }

    async searchUsersByOrganisationId(organisationId: string) {
        const searchParams: SearchParams = {
            searchString: '',
            size: 1000,
            from: 0,
            filter: {
                'organisationId.keyword': organisationId,
            },
        };
        this.setDefaultSearchParams(searchParams);
        return this.searchUser(searchParams);
    }

    private prettifyResult(res: any) {
        return { hits: res.hits.hits, total: res.hits.total.value };
    }

    private mapResult(res: any) {
        if (res.hits && res.hits.hits) {
            return res.hits.hits.map((hit) => hit._source);
        }
        return [];
    }

    terminate() {
        this.textSub.unsubscribe();
    }
}
